import React, { useEffect, useState } from "react";
import { ensureAccount, userDetails } from "../../services/parse";
import "./user.css";

export function Avatar(props: { playerId?: string, className?: string}) {
    const [stats, setStats] = useState({ name: '未知玩家', avatar: '' });

    const { playerId } = props;
    useEffect(function() {
        if (playerId) userDetails(playerId).then(setStats);
        return function() {
            setStats({ name: '未知玩家', avatar: '' });
        }
    }, [playerId]);

    return <div className={"user-label " + (props.className || "")}>
        <img src={stats.avatar} alt=''/>
        <h3>{stats.name}</h3>
    </div>
}

export function SelfAvatar(props: { className?: string}) {
    const [user, setUser] = useState(null as (null | Parse.User));

    useEffect(function() {
        ensureAccount().then(setUser);
    }, []);

    return <Avatar playerId={user ? user.id : undefined} {...props} />
}
