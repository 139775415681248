"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.access = void 0;
function getProp(obj, ...keys) {
    for (const key of keys) {
        obj = obj[key];
    }
    return obj;
}
function setProp(obj, val, ...keys) {
    let i = keys.length;
    if (i <= 0)
        throw new Error("there should be at least 1 key");
    for (const key of keys) {
        if (--i == 0) {
            obj[key] = val;
            return;
        }
        else {
            obj = obj[key];
        }
    }
}
function find(obj, ...keys) {
    return {
        get value() {
            return getProp(obj, ...keys);
        },
        set value(val) {
            setProp(obj, val, ...keys);
        },
        get parent() {
            return find(obj, ...keys.slice(0, keys.length - 1));
        },
        findChild(key) {
            return find(obj, ...keys, key);
        },
        get keys() {
            return keys;
        }
    };
}
exports.access = {
    get: getProp,
    set: setProp,
    find
};
