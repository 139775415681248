"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.offDown = exports.onDown = exports.useOnPointer = void 0;
function useOnPointer(options, ctx) {
    while (ctx.parent)
        ctx = ctx.parent;
    const onDown = (e) => {
        options.onDown && options.onDown(e);
        ctx.interactive = true;
        ctx.on('pointermove', onMove);
        ctx.on('mousemove', onMove);
        ctx.on('pointerup', onUp);
        ctx.on('mouseup', onUp);
        ctx.on('pointerout', onUp);
        ctx.on('mouseout', onUp);
    };
    const onMove = (e) => {
        options.onMove && options.onMove(e);
    };
    const onUp = (e) => {
        options.onUp && options.onUp(e);
        ctx.off('pointermove', onMove);
        ctx.off('mousemove', onMove);
        ctx.off('pointerup', onUp);
        ctx.off('mouseup', onUp);
        ctx.off('pointerout', onUp);
        ctx.off('mouseout', onUp);
    };
    return onDown;
}
exports.useOnPointer = useOnPointer;
function onDown(target, onDown) {
    target.interactive = true;
    target.on('pointerdown', onDown);
    // target.on('mousedown', onDown);
}
exports.onDown = onDown;
function offDown(target, onDown) {
    target.off('pointerdown', onDown);
    // target.off('mousedown', onDown);
}
exports.offDown = offDown;
