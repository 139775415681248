"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PixiIntentDropSystem = exports.PixiInteractiveSystem = void 0;
const ecs_1 = require("@tabletop/ecs");
const PIXI = __importStar(require("pixi.js"));
const db_1 = require("./db");
const pixi_db_1 = require("./pixi-db");
const pointer_1 = require("./pointer");
class PixiInteractiveSystem extends ecs_1.System {
    constructor(mount, zones, onIntent, onDoubleClick) {
        super('interactive');
        this.mount = mount;
        this.zones = zones;
        this.onIntent = onIntent;
        this.onDoubleClick = onDoubleClick;
        this.states = {};
    }
    onDrop(ekey, pt) {
        for (const zkey in this.zones.roles) {
            const zone = this.mount.get(zkey);
            const dx = pt.x - zone.x;
            const dy = pt.y - zone.y;
            if (dx < 0 || dx >= zone.width)
                continue;
            if (dy < 0 || dy >= zone.height)
                continue;
            const otherRoles = this.zones.roles[zkey];
            const selfRoles = this.states[ekey].roles;
            for (const intent in selfRoles) {
                if (!otherRoles[intent])
                    continue;
                const i = {
                    intent,
                };
                i[selfRoles[intent]] = ekey;
                i[otherRoles[intent]] = zkey;
                this.onIntent && this.onIntent(i);
                return;
            }
        }
    }
    onCreated(ekey, t) {
        const target = this.mount.get(ekey);
        this.states[ekey] = new PixiInteractiveState(target);
        this.states[ekey].onDrop = pt => this.onDrop(ekey, pt);
        this.states[ekey].dc.trigger = this.onDoubleClick ?
            (() => this.onDoubleClick(ekey)) : noop;
        this.onApply(ekey, t);
    }
    onDestroyed(ekey) {
        this.states[ekey].disable();
        delete this.states[ekey];
    }
    onApply(ekey, t) {
        const target = this.mount.get(ekey);
        target.interactive = !!t.enabled;
        if (t.enabled)
            this.states[ekey].enable();
        else
            this.states[ekey].disable();
        this.states[ekey].roles = t.roles;
    }
    onTick(delta) {
        for (const key in this.states) {
            this.states[key].onTick(delta);
        }
    }
}
exports.PixiInteractiveSystem = PixiInteractiveSystem;
function noop() { }
class DoubleClicker {
    constructor(trigger = noop, threshold = 300) {
        this.trigger = trigger;
        this.threshold = threshold;
        this.timeout = 0;
        this.tap = () => {
            if (this.timeout) {
                this.trigger();
                clearTimeout(this.timeout);
                this.timeout = 0;
            }
            else {
                this.timeout = setTimeout(() => {
                    this.timeout = 0;
                }, this.threshold);
            }
        };
    }
}
class PixiInteractiveState {
    constructor(target) {
        this.target = target;
        this.hover = false;
        this.press = false;
        this.drag = false;
        this.dx = new ecs_1.TargetSpring(.06, .3);
        this.dy = new ecs_1.TargetSpring(.06, .3);
        this.roles = {};
        this.dc = new DoubleClicker();
        this.downPt = { x: 0, y: 0 };
        this.movePt = { x: 0, y: 0 };
        this.onEnter = () => {
            if (!this.hover)
                this.animate('hover', true);
            this.hover = true;
        };
        this.onExit = () => {
            if (this.hover)
                this.animate('hover', false);
            this.hover = false;
        };
        this.onDown = pointer_1.useOnPointer({
            onDown: (e) => {
                if (!this.press)
                    this.animate('press', true);
                this.press = true;
                this.downPt = this.movePt = e.data.getLocalPosition(this.target.parent);
                this.target.zIndex += 1000;
                this.target.parent.sortChildren();
            },
            onMove: (e) => {
                const pt = this.movePt = e.data.getLocalPosition(this.target.parent);
                const dx = pt.x - this.downPt.x;
                const dy = pt.y - this.downPt.y;
                const dragNow = (dx * dx + dy * dy) >= 25;
                if (dragNow && !this.drag)
                    this.animate('drag', true);
                this.drag = this.drag || dragNow;
                if (!this.drag)
                    return;
                this.dx.target = dx;
                this.dy.target = dy;
            },
            onUp: () => {
                if (this.press) {
                    this.dc.tap();
                    this.animate('press', false);
                }
                this.press = false;
                if (this.drag)
                    this.animate('drag', false);
                this.drag = false;
                this.target.zIndex -= 1000;
                this.target.parent.sortChildren();
                this.dx.target = 0;
                this.dy.target = 0;
                this.movePt == this.downPt || this.onDrop(this.movePt);
            }
        }, this.target.parent);
        this._enabled = false;
    }
    animate(key, flag) {
        if (this.target instanceof db_1.PixiArmatureDisplay) {
            pixi_db_1.setAnimationFlag(this.target, key, flag);
        }
    }
    onDrop(_pt) {
    }
    onTick(delta) {
        this.target.position.x -= this.dx.position;
        this.target.position.y -= this.dy.position;
        this.dx.stepTarget(delta / 16);
        this.dy.stepTarget(delta / 16);
        this.target.position.x += this.dx.position;
        this.target.position.y += this.dy.position;
    }
    get enabled() { return this._enabled; }
    enable() {
        if (this._enabled)
            return;
        this._enabled = true;
        this.target.on('mouseover', this.onEnter);
        this.target.on('mouseout', this.onExit);
        pointer_1.onDown(this.target, this.onDown);
    }
    disable() {
        if (!this._enabled)
            return;
        this._enabled = false;
        this.target.off('mouseover', this.onEnter);
        this.target.off('mouseout', this.onExit);
        pointer_1.offDown(this.target, this.onDown);
    }
}
class PixiIntentDropSystem extends ecs_1.System {
    constructor(mount) {
        super('dropzone');
        this.mount = mount;
        this.roles = {};
    }
    onCreated(ekey, roles) {
        const zone = new PIXI.Sprite(PIXI.Texture.WHITE);
        this.mount.onCreated(ekey, zone);
        zone.visible = false;
        // const zone = this.rects[ekey] = ;
        // zone.interactive = true;
        // zone.on('pointerenter', () => zone.alpha = .2);
        // zone.on('pointerleave', () => zone.alpha = 0);
        // zone.alpha = 0;
        // this.mount.addChild(zone);
        this.onApply(ekey, roles);
    }
    onDestroyed(ekey) {
        // this.mount.removeChild(this.rects[ekey]);
        this.mount.onDestroyed(ekey);
        delete this.roles[ekey];
    }
    onApply(ekey, roles) {
        const [x, y, w, h] = roles.zone;
        const zone = this.mount.get(ekey);
        zone.width = w;
        zone.height = h;
        zone.position.set(x - w / 2, y - h / 2);
        this.roles[ekey] = roles.roles;
    }
}
exports.PixiIntentDropSystem = PixiIntentDropSystem;
