import { avatarLink } from "./avataaar";
import { Parse } from "./setup";

function genHash(prefix?: string) {
    let hash = prefix;
    for (let i = 0; i < 2; i++)
        hash += Math.random().toString(16).slice(-8);
    return hash;
}

export async function obtainAnonymousAccount() {
    for (let i = 0; i < 10; i++) {
        const user = new Parse.User();
        user.set("username", genHash("autouser_"));
        user.set("password", genHash(""));

        try {
            await user.signUp();
        } catch (e) {
            continue;
        }
        return user;
    }
    throw new Error("cannot register after 10 attempts");
}

let promise: Promise<Parse.User> | undefined;
export async function ensureAccount() {
    const user = Parse.User.current();
    if (user) return user;
    if (!promise) {
        promise = obtainAnonymousAccount();
    }
    const result = await promise;
    promise = undefined;
    return result;
}

export async function userDetails(userId: string) {
    // const user = await new Parse.Query(Parse.User).get(userId);
    // const name = user.getUsername()!;
    // const avatar = user.get('avatar') as string ||
    //     // `https://robohash.org/${name}?set=set4&size=200x200`;
    //     avatarLink(name);

    // return { name, avatar };
    return {
        name: userId,
        avatar: avatarLink(userId)
    };
}

export async function userRecentRooms(userId: string) {
    return await new Parse.Query("GameRoom").contains("users", userId).descending("updatedAt").limit(10).find();
}
