import * as PIXI from "pixi.js";
import React, { Fragment, useEffect, useMemo } from "react";
import { ActionButton, DBArmatureComponent, DBArmatureSlotReplacement, DBCounters, DropRoot, DropZone, EventTargetComponent, FieldAnchor, HandAnchor, Intent, InteractiveObject, LayoutAnchor, PileAnchor, PixiApp, PixiBackground, PixiMount, PixiTransform, Viewport } from "tabletop-game";
import { cards } from "@tabletop/noodle-game";

interface CardKey {
    cardkey: string,
    anchor: LayoutAnchor,
    total: number, index: number
}
export class CardTarget extends EventTargetComponent<CardKey>{
    shouldUpdate(before: CardKey, after: CardKey) {
        return before.cardkey !== after.cardkey ||
            before.anchor !== after.anchor ||
            before.total !== after.total ||
            before.index !== after.index;
    }

    render(props: CardKey) {
        const { cardkey, anchor, total, index } = props;
        return <GameCard cardkey={cardkey}>
            <InteractiveObject anchor={anchor} i={index} total={total} ikey={props} />
        </GameCard >
    }
}

interface ZoneKey {
    key: string,
    rect: PIXI.Rectangle,
    onDrop: (key: any, x: number, y: number) => void
}

export class ZoneTarget extends EventTargetComponent<ZoneKey>{
    render(props: ZoneKey) {
        const { key, rect, onDrop } = props;
        return <DropZone key={key} x={rect.x + rect.width / 2} y={rect.y + rect.height / 2}
            width={rect.width} height={rect.height}
            onDrop={onDrop} />
    }
}

class ExpandedFieldAnchor extends FieldAnchor {
    animation() {
        return { expand: true, flip: false };
    }
}
export const anchors = {
    ingredients: {
        draw: new PileAnchor(-1300, -500, false),
        discard: new PileAnchor(-1700, -500, true),
        pick: new ExpandedFieldAnchor(0, -500, 2000, 400),
    },

    flavors: {
        draw: new PileAnchor(-1400, 0, false),
        discard: new PileAnchor(-2000, 0, true),
        bargain: new PileAnchor(1200, 0, true),
        pick: new ExpandedFieldAnchor(0, 0, 2000, 400),
    },

    customers: {
        draw: new PileAnchor(-1300, 500, false),
        discard: new PileAnchor(-1700, 500, true),
        pick: new ExpandedFieldAnchor(0, 500, 2000, 400),
    },

    player: {
        hand: new HandAnchor(0, 1800, 3000, 5000, 400, true),
        bowls: [
            new FieldAnchor(-1000, 1100, 800, 300),
            new FieldAnchor(0, 1100, 800, 300),
            new FieldAnchor(1000, 1100, 800, 300),
        ]
    },
}

function dropActionZone(x: number, y: number, width: number, height: number,
    onDropIntent: (item: CardKey) => Intent | undefined) {
    return { x, y, width, height, onDropIntent }
}

function dropBowl(bowl: number) {
    return (item: CardKey) => {
        if (cards.all[item.cardkey].type === '点单') {
            if (item.anchor === anchors.player.hand) {
                return { intent: 'play_flavor', hand: item.cardkey, bowl };
            } else if (item.anchor === anchors.flavors.bargain || item.anchor === anchors.flavors.pick) {
                return { intent: 'pick_flavor', pick: item.cardkey, bowl };
            }
        } else if (cards.all[item.cardkey].type === '食材') {
            if (item.anchor === anchors.player.hand) {
                return { intent: 'play_ingredient', hand: item.cardkey, bowl };
            }
        }
    }
}
function dropHand(item: CardKey) {
    if (item.anchor === anchors.ingredients.draw) {
        return { intent: 'draw_ingredient' };
    } else if (item.anchor === anchors.ingredients.pick) {
        return { intent: 'pick_ingredient', pick: item.cardkey };
    } else if (item.anchor === anchors.customers.pick) {
        return { intent: 'pick_customer', customer: item.cardkey };
    }
}
export const dropZones = [
    dropActionZone(-1000, 1050, 900, 400, dropBowl(0)),
    dropActionZone(0, 1050, 900, 400, dropBowl(1)),
    dropActionZone(1000, 1050, 900, 400, dropBowl(2)),
    dropActionZone(0, 2000, 3000, 1400, dropHand)
];

export function DebugRect(props: { x: number, y: number, width: number, height: number }) {
    const rect = useMemo(function() {
        return new PIXI.Sprite(PIXI.Texture.WHITE);
    }, []);
    useEffect(function() {
        rect.alpha = .2;
        rect.width = props.width;
        rect.height = props.height;
        rect.x = -props.width / 2 + props.x;
        rect.y = -props.height / 2 + props.y;
    }, [props, rect]);
    useEffect(() => {
        return function() {
            rect.destroy();
        }
    }, [rect]);

    return <PixiMount mounted={rect} />
}

export function GameDropActions(props: { onIntent: (i: Intent) => void }) {
    return <Fragment> {
        dropZones.map(
            (zone, i) => <Fragment key={i}>
                <DropZone onDrop={cardkey => {
                    const intent = zone.onDropIntent(cardkey);
                    if (intent) props.onIntent(intent);
                }} x={zone.x} y={zone.y} width={zone.width} height={zone.height} />
                <DebugRect x={zone.x} y={zone.y} width={zone.width} height={zone.height} />
            </Fragment>
        )
    } </Fragment>
}

export function GameButton(props: { text: string, children?: any, onClick: () => void }) {
    return <DBArmatureComponent path="db/小面/" armature="Action" bundle="牌面动画">
        <GameText text={props.text} />
        <ActionButton onClick={props.onClick}>
            {props.children}
        </ActionButton>
    </DBArmatureComponent>
}

export function GameTable(props: { children?: any }) {
    const w = window.innerWidth;
    const h = Math.round(w * .6);
    const res = window.devicePixelRatio;
    const zoom = w / 1000 / 5;
    return <PixiApp className="pixi-container"
        pixiParams={{
            antialias: true,
            width: w,
            height: h,
            resolution: res,
        }}>
        <Viewport zoom={zoom} y={300}>
            <PixiBackground url="db/小面/bg.jpg">
                <PixiTransform sx={2} sy={2} x={-2500} y={-2000} />
            </PixiBackground>
            <DropRoot>{props.children} </DropRoot>
        </Viewport>
    </PixiApp>
}

const armatures: { [key: string]: string } = {
    '食材': 'Ingredient',
    '顾客': 'Customer',
    '点单': 'Flavor'
};
export function GameCard(props: { cardkey: string, children?: any }) {
    const card = cards.all[props.cardkey];
    const armature = card && armatures[card.type];
    if (!armature) return null;

    return <DBArmatureComponent path="db/小面/" armature={armature} bundle="牌面动画">
        <DBArmatureSlotReplacement slot="牌面" replaced={`db/小面/${card.name}.png`} />
        {props.children}
    </DBArmatureComponent>
}

const numToString = {
    alphabet: '0123456789',
    toString(v: number) {
        return `${v}`;
    }
};
export function GameNumber(props: { value: number, children?: any }) {
    return <DBCounters counter={numToString} val={props.value} dx={60} dy={0}
        armature="Character" path="db/小面/" bundle="牌面动画" slot="Symbols">
        {props.children}
    </DBCounters>
}

export function GameText(props: { text: string, children?: any }) {
    const text = useMemo(function() {
        return new PIXI.Text("", { fill: 0xffffff, fontSize: 40 });
    }, []);
    useEffect(function() {
        text.text = props.text;
        text.x -= text.width / 2;
        text.y -= text.height / 2;
        return function() {
            text.x += text.width / 2;
            text.y += text.height / 2;
        }
    }, [text, props.text])
    useEffect(() => {
        return function() {
            text.destroy();
        }
    }, [text]);

    return <PixiMount mounted={text} children={props.children} />
}
