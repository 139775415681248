import { cards, Customer, Flavor, Ingredient } from "@tabletop/noodle-game";
import { PixiEcsApp } from "@tabletop/pixidb";
import React, { Fragment, ReactNode, useEffect, useState } from "react";
import { PortalContainer, usePopup } from "../parse";
import { useEcs } from "./app";

function DocPopup(props: { active: boolean, close: () => void, children?: any }) {
    return <PortalContainer active={props.active}>
        <div className="full flex dimmed flex-col">
            <div style={{ height: '100%', overflow: 'auto' }} onClick={props.close}>
                <div className="collapse paper flex flex-col" style={{ margin: '5em 1em' }}>
                    {props.children}
                </div>
            </div>
            <button onClick={props.close}>关闭</button>
        </div>
    </PortalContainer>;
}
function DocHelp(props: { label: ReactNode, children?: any }) {
    const popup = usePopup();
    const dialog = popup.exist() && <DocPopup children={props.children}
        active={popup.active()} close={popup.close} />;
    const button = <button children={props.label} onClick={() => popup.open()} />;
    return <Fragment>
        {dialog}{button}
    </Fragment>
}

export function GameHelp() {
    return <DocHelp label="规则">
        <h3>小面桌游简介</h3>

                每名玩家做三碗面，三碗面总分最高的玩家获胜。<br /><br />

                每碗面里有最多5张食材和1张点单。有点单的面才能得分。<br /><br />

                行动时可以从食材区取得食材、将取得的食材下到碗里、或者将点单下到碗里。<br /><br />

                玩家轮流行动，每回合行动2次，首次行动的玩家只行动1次。<br /><br />

                当有玩家的三碗面里均有点单时，剩余玩家再进行一回合，然后游戏结束。<br /><br />

        <h3>食材牌</h3>

                食材牌分为荤菜、素菜、佐料三种，各自具有不同颜色的牌面背景。<br /><br />

                部分荤菜也是海鲜。红油的背景是红色的，但也算作佐料。<br /><br />

        <h3>点单牌</h3>

                点单牌包含价格信息和配料表。<br /><br />

                每张点单包含大份价格和小份价格。<br /><br />
                当面中食材数目达到5张时，获得大份得分，否则获得小份得分。<br /><br />

                配料表中会将食材标为若干、推荐或者禁选。<br /><br />
                如果碗中缺少标为若干的食材，则不能将此点单下到碗里。<br /><br />
                如果碗中重复标记某种食材要求为若干，则每重复一次，都需要一种不同的该类食材。<br /><br />
                如果碗中存在推荐或禁选的食材，则分别获得+2/-3分加成。<br /><br />

        <h3>计分</h3>

                计分分为基本分和点单分两部分。
                基本分规则为：
                <ul>
            <li>若有荤菜+3分。</li>
            <li>若有素菜+2分。</li>
            <li>若有佐料+1分。</li>
            <li>每对相同食材+2分。</li>
            <li>每3张相同食材+5分。</li>
        </ul>
                点单分规则为：
                <ul>
            <li>若有5张食材获得大份得分。</li>
            <li>否则获得小份得分。</li>
            <li>每项推荐+2分。</li>
            <li>每项禁选-3分。</li>
        </ul>

        <h3>行动</h3>

                每次行动可以选择以下当中的一项：
                <ul>
            <li>选择一张食材区中的食材加入手牌。从食材牌堆中发一张食材到食材区。</li>
            <li>从食材牌堆抽一张。从食材牌堆中重新发三张食材到食材区。</li>
            <li>将任意张相同食材放到自己的一个碗里。</li>
            <li>将一张点单从手里放到自己的一个碗里。</li>
            <li>将一张点单区内点单放到自己的一个碗里，然后发一张新点单。</li>
        </ul>
    </DocHelp>
}

export function GameCustomersHelp() {
    return <DocHelp label="顾客一览">
        <CustomerCard name="打工人" />
        <CustomerCard name="小学生" />
        <CustomerCard name="大彪汉" />
        <CustomerCard name="大胃王" />
        <CustomerCard name="急性子" />
        <CustomerCard name="辣妹子" />
        <CustomerCard name="花泽" />
        <CustomerCard name="海王" />
    </DocHelp>
}

function CustomerCard(props: { name: string }) {
    const { name } = props;
    const cardid = cards.customers.find(c => cards.all[c].name === name)!;
    const card = cards.all[cardid] as Customer;

    const desc = <Fragment>
        <div>投{`${card.throws}`}个骰子</div>
        <div>分数为其中最小的{`${card.dices}`}个骰子点数之和</div>
        <div>完成{`${card.condition}`}的玩家，可获得此顾客</div>
    </Fragment>
    const src = `db/小面/${name}.png`;
    return <Card name={name} desc={desc} src={src} />
}

export function GameFlavorsHelp() {
    return <DocHelp label="点单一览">
        <FlavorCard name="番茄煎蛋面" />
        <FlavorCard name="红烧排骨面" />
        <FlavorCard name="红烧牛肉面" />
        <FlavorCard name="海味面" />
        <FlavorCard name="脆绍面" />
        <FlavorCard name="怪味面" />
        <FlavorCard name="肥肠面" />
        <FlavorCard name="碗杂面" />
    </DocHelp>
}

function FlavorCard(props: { name: string }) {
    const { name } = props;
    const cardid = cards.flavors.find(c => cards.all[c].name === name)! || cards.bargains[0];
    const card = cards.all[cardid] as Flavor;

    if (card.name == '特价面') {
        return <Card name="特价面" src="db/小面/特价面.png" desc={
            <Fragment>
                <div>{`￥${card.major}/￥${card.minor}`}</div>
                <div>只能在你的完成面数为所有玩家中最低之一时可完成</div>
            </Fragment>
        } />
    }

    const reqs = card.req.join('，');
    const recs = card.rec.map(r => r + '推荐').join('，');
    const bans = card.ban.map(r => r + '禁选').join('，');
    const desc = <Fragment>
        <div>{`￥${card.major}/￥${card.minor}`}</div>
        <div>{`${reqs}`}</div>
        <div>{`${recs}  ${bans}`}</div>
    </Fragment>
    const src = `db/小面/${name}.png`;
    return <Card name={name} desc={desc} src={src} />
}

export function GameIngredientsHelp() {
    return <DocHelp label="食材一览">
        <IngCard name="墨鱼" />
        <IngCard name="墨鱼" />
        <IngCard name="虾" />
        <IngCard name="花甲" />
        <IngCard name="臊子" />
        <IngCard name="牛肉" />
        <IngCard name="肥肠" />
        <IngCard name="排骨" />
        <IngCard name="煎蛋" />

        <IngCard name="番茄" />
        <IngCard name="软浆叶" />
        <IngCard name="香菇" />
        <IngCard name="豌豆尖" />
        <IngCard name="瓢儿白" />
        <IngCard name="芹菜" />

        <IngCard name="红油" />
        <IngCard name="盐须" />
        <IngCard name="葱花" />
    </DocHelp>
}

export function GameCardHelp() {
    const [ekey, setEkey] = useState('');
    const card = cards.all[ekey];

    const ecs = useEcs() as PixiEcsApp;
    useEffect(function() {
        ecs.interactiveSystem.onDoubleClick = setEkey;
        return function() {
            ecs.interactiveSystem.onDoubleClick = undefined;
        }
    }, [ecs, setEkey]);

    const popup = usePopup();
    useEffect(function() {
        const type = card && card.type;
        if (!type || !(type === '食材' || type === '点单' || type === '顾客'))
            return;

        const flipped = ecs.world.entity_components[ekey]['armatureAnimations'].flip;
        if(flipped)return;

        popup.open();
        return function(){
            popup.close();
        }
    }, [ecs, popup, ekey, card]);

    return popup.exist() ? <DocPopup active={popup.active()} close={() => setEkey('')}>
        {card && card.type === '食材' ? <IngCard name={card.name} /> : null}
        {card && card.type === '点单' ? <FlavorCard name={card.name} /> : null}
        {card && card.type === '顾客' ? <CustomerCard name={card.name} /> : null}
    </DocPopup> : null;
}

function IngCard(props: { name: string }) {
    const { name } = props;
    const cardid = cards.ingredients.find(c => cards.all[c].name === name)!;
    const card = cards.all[cardid] as Ingredient;

    const count = cards.ingredients.reduce((a, b) => cards.all[b].name === name ? (a + 1) : a, 0);
    const desc = `${card.subtype}，${card.isSeafood ? '海鲜' : '非海鲜'}，共${count}张`;
    const src = `db/小面/${name}.png`;
    return <Card name={name} desc={desc} src={src} />
}

function Card(props: { name: string, desc: ReactNode, src: string }) {
    return <Fragment>
        <h3 style={{ margin: '1em 0 0 0' }}>{props.name}</h3>
        <h4 style={{ margin: '0 0 .5em 0', color: 'slategrey' }}>{props.desc}</h4>
        <img src={props.src} style={{ maxWidth: '100%', objectFit: 'contain' }} alt={props.name} />
    </Fragment>
}
