"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GameInstance = void 0;
const immer_1 = require("immer");
const ephemeral_1 = require("./ephemeral");
const eventstack_1 = require("./eventstack");
const funclib_1 = require("./funclib");
const random_1 = require("./random");
class GameInstance {
    constructor() {
        this.selectors = new funclib_1.FunctionLibrary();
        this.expansors = new funclib_1.FunctionLibrary();
        this.reducers = new funclib_1.FunctionLibrary();
        this._doSelect = () => undefined;
        this.ephemerals = ephemeral_1.createEphProxy({
            random: random_1.random, stack: eventstack_1.stack, select: {
                onCreate: () => this._doSelect,
                onDestroy() { }
            }
        }, null);
    }
    asDraft(current, cb) {
        let error = null;
        current = immer_1.produce(current, current => {
            current.store = current.store || {};
            current.game = current.game || {};
            this.ephemerals.setStore(current.store);
            this._doSelect = (name, arg) => this.selectors.call(name, current.game, arg, this.ephemerals.ctx);
            try {
                cb(current);
            }
            catch (e) {
                error = e;
            }
            this.ephemerals.cleanUp();
        });
        if (error)
            throw error;
        return current;
    }
    select(current, selector, param) {
        let result = undefined;
        this.asDraft(current, current => {
            result = this.selectors.call(selector, current.game, param, this.ephemerals.ctx);
        });
        return result;
    }
    applyIntent(current, intent) {
        return this.asDraft(current, current => {
            const stack = this.ephemerals.ctx.stack;
            const event = { ...stack.head(), intent };
            const events = this.expansors.call(event.event, current.game, event, this.ephemerals.ctx);
            const i = stack.expandHead(events);
            if (i === 0)
                return;
            const effects = stack.resolved.slice(-i)
                .map(i => stack.nodes[i].node);
            for (const one of effects) {
                const res = this.reducers.call(one.effect, current.game, one, this.ephemerals.ctx);
                current.game = res || current.game;
            }
        });
    }
    pass(current) {
        return this.applyIntent(current, { intent: 'pass' });
    }
    applyIntents(current, intents) {
        for (const intent of intents) {
            current = this.applyIntent(current, intent);
        }
        return current;
    }
}
exports.GameInstance = GameInstance;
