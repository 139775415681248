import qrcode from "qrcode";
import React, { HTMLAttributes, useEffect, useState } from "react";

export function QRCode(props: { text: string } & HTMLAttributes<HTMLDivElement>) {
    const { text, ...others } = props;
    const [url, setUrl] = useState('');

    useEffect(function() {
        qrcode.toDataURL(text, function(_err, url) {
            setUrl(url);
        });
    }, [text]);

    return <div style={{
        imageRendering: 'pixelated',
        backgroundImage: `url("${url}")`,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        maxWidth: '70vw',
        maxHeight: '70vh',
        width: '70vw',
        height: '70vw',
    }} {...others} />
}

export function LocationQRCode(props: HTMLAttributes<HTMLDivElement>){
    const location = window.location;
    return <QRCode text={location.href} {...props}/>
}
