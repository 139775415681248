"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PixiEcsApp = exports.PixiTransformSystem = exports.PixiSlotSystem = exports.PixiBoolAnimationSystem = exports.PixiArmatureSystem = exports.PixiCounterSystem = exports.PixiMount = void 0;
const ecs_1 = require("@tabletop/ecs");
const PIXI = __importStar(require("pixi.js"));
const pixi_js_1 = require("pixi.js");
const interactive_1 = require("./interactive");
const pixi_db_1 = require("./pixi-db");
__exportStar(require("./interactive"), exports);
class PixiMount {
    constructor(mount) {
        this.mount = mount;
        this.dos = {};
    }
    get(ekey) {
        return this.dos[ekey];
    }
    onCreated(ekey, p) {
        this.dos[ekey] = p;
        this.mount.addChild(this.dos[ekey]);
    }
    onDestroyed(ekey) {
        const display = this.dos[ekey];
        this.mount.removeChild(display);
        display.destroy();
        delete this.dos[ekey];
    }
}
exports.PixiMount = PixiMount;
class PixiCounterSystem extends ecs_1.System {
    constructor(mount) {
        super('armatureCounter');
        this.mount = mount;
    }
    onCreated(ekey, p) {
        const c = new pixi_js_1.Container();
        this.mount.onCreated(ekey, c);
        this.onApply(ekey, p);
    }
    onDestroyed(ekey) {
        if (this.mount.get(ekey))
            this.mount.onDestroyed(ekey);
    }
    *getFrames(p) {
        let x = 0;
        let y = 0;
        for (const c of p.frames) {
            if (c == '\n') {
                y++;
                x = 0;
                continue;
            }
            else if (c == ' ') {
                x++;
                continue;
            }
            const frame = p.alphabet.indexOf(c);
            yield { frame, x: x * p.ux + y * p.vx, y: x * p.uy + y * p.vy };
            x++;
        }
    }
    onApply(ekey, p) {
        const c = this.mount.get(ekey);
        const frames = [...this.getFrames(p)];
        for (let i = c.children.length; i < frames.length; i++) {
            const char = pixi_db_1.createArmatureDisplay(p.armature, p.bundle);
            char.position.set(frames[i].x, frames[i].y);
            c.addChild(char);
            const slot = char.armature.getSlot(p.slot);
            const display = slot.display;
            const animation = display.animation;
            const newState = animation.gotoAndPlayByTime('Symbols', frames[i].frame / 24);
            newState.timeScale = 0;
        }
        for (let i = c.children.length - 1; i >= frames.length; i--) {
            c.removeChild(c.children[i]).destroy();
        }
        for (let i = 0; i < c.children.length; i++) {
            const char = c.children[i];
            char.position.set(frames[i].x, frames[i].y);
            const slot = char.armature.getSlot(p.slot);
            const display = slot.display;
            const animation = display.animation;
            const state = animation.getState('Symbols');
            const prevTime = state && state.currentTime;
            const newState = animation.gotoAndPlayByTime('Symbols', frames[i].frame / 24);
            newState.timeScale = 0;
            if (newState.currentTime != prevTime) {
                char.animation.fadeIn('jump', 0, 1);
            }
        }
    }
}
exports.PixiCounterSystem = PixiCounterSystem;
class PixiArmatureSystem extends ecs_1.System {
    constructor(mount) {
        super('armature');
        this.mount = mount;
    }
    onCreated(ekey, p) {
        const armature = pixi_db_1.createArmatureDisplay(p.armature, p.bundle);
        this.mount.onCreated(ekey, armature);
    }
    onDestroyed(ekey) {
        this.mount.onDestroyed(ekey);
    }
    onApply(_ekey, _p) {
        // this.onDestroyed(ekey);
        // this.onCreated(ekey, p);
    }
}
exports.PixiArmatureSystem = PixiArmatureSystem;
class PixiBoolAnimationSystem extends ecs_1.System {
    constructor(mount) {
        super('armatureAnimations');
        this.mount = mount;
    }
    onDestroyed(ekey) {
        const target = this.mount.get(ekey);
        target.animation.stop();
    }
    onApply(ekey, flags) {
        const target = this.mount.get(ekey);
        for (const flag in flags) {
            pixi_db_1.setAnimationFlag(target, flag, !!flags[flag]);
        }
    }
    onCreated(ekey, flags) {
        const target = this.mount.get(ekey);
        for (const flag in flags) {
            const anim = pixi_db_1.setAnimationFlag(target, flag, !!flags[flag]);
            if (!anim || !flags[flag])
                continue;
            //@ts-ignore
            anim._time += anim.totalTime;
        }
    }
}
exports.PixiBoolAnimationSystem = PixiBoolAnimationSystem;
class PixiSlotSystem extends ecs_1.System {
    constructor(mount) {
        super('armatureSlots');
        this.mount = mount;
        this.textures = {};
    }
    onApply(ekey, textures) {
        const target = this.mount.get(ekey);
        for (const slot in textures) {
            const save = target.armature.getSlot(slot);
            if (!save) {
                console.error('slot is null');
                continue;
            }
            if (!this.textures[textures[slot]]) {
                this.textures[textures[slot]] = PIXI.BaseTexture.from(textures[slot]);
            }
            const texture = this.textures[textures[slot]];
            const sprite = save.display;
            const { width, height } = sprite.texture.frame;
            const t = new PIXI.Texture(texture, undefined, undefined, new PIXI.Rectangle(0, 0, width, height));
            sprite.texture = t;
        }
    }
}
exports.PixiSlotSystem = PixiSlotSystem;
class PixiTransformSystem extends ecs_1.System {
    constructor(mount) {
        super('armatureTransform');
        this.mount = mount;
        this.transforms = {};
        this.timeout = 0;
        this.sortZImpl = () => {
            if (this.mount.mount.sortDirty)
                this.mount.mount.sortChildren();
            this.timeout = 0;
        };
    }
    sort() {
        if (this.timeout)
            return;
        this.timeout = setTimeout(this.sortZImpl, 0);
    }
    apply(target, transform, reverse = false) {
        const { x, y, r, sx, sy, z } = transform;
        if (x !== undefined) {
            target.position.x += reverse ? (-x) : x;
        }
        if (y !== undefined) {
            target.position.y += reverse ? (-y) : y;
        }
        if (r !== undefined) {
            target.rotation += reverse ? (-r) : r;
        }
        if (sx !== undefined) {
            target.scale.x += reverse ? (-sx) : sx;
        }
        if (sy !== undefined) {
            target.scale.y += reverse ? (-sy) : sy;
        }
        if (z !== undefined) {
            target.zIndex += reverse ? (-z) : z;
            this.sort();
        }
    }
    onCreated(ekey, t) {
        const target = this.mount.get(ekey);
        const transform = this.transforms[ekey] = { ...t };
        this.apply(target, transform);
    }
    onDestroyed(ekey) {
        const target = this.mount.get(ekey);
        const transform = this.transforms[ekey];
        if (!transform)
            return;
        this.apply(target, transform, true);
        delete this.transforms[ekey];
    }
    onApply(ekey, t) {
        const old = this.transforms[ekey];
        if (!old) {
            this.onCreated(ekey, t);
            return;
        }
        this.apply(this.mount.get(ekey), old, true);
        old.x = t.x;
        old.y = t.y;
        old.z = t.z;
        old.sx = t.sx;
        old.sy = t.sy;
        old.r = t.r;
        this.apply(this.mount.get(ekey), old);
    }
}
exports.PixiTransformSystem = PixiTransformSystem;
class TransformSpringSystem extends ecs_1.SpringSystem {
    constructor(mount) {
        super('transform');
        this.mount = mount;
        this.transformSystem = new PixiTransformSystem(mount);
    }
    onUpdate(ekey, vals) {
        this.transformSystem.onApply(ekey, vals);
    }
    onDestroyed(ekey) {
        this.transformSystem.onDestroyed(ekey);
        super.onDestroyed(ekey);
    }
}
class PixiEcsApp extends ecs_1.EcsCtx {
    constructor(viewport) {
        super();
        this.viewport = viewport;
        const mount = new PixiMount(viewport);
        const intents = new interactive_1.PixiIntentDropSystem(mount);
        const armatures = new PixiArmatureSystem(mount);
        const counters = new PixiCounterSystem(mount);
        const armatureAnimations = new PixiBoolAnimationSystem(mount);
        const armatureSlots = new PixiSlotSystem(mount);
        this.interactiveSystem = new interactive_1.PixiInteractiveSystem(mount, intents);
        const springs = new TransformSpringSystem(mount);
        this.systems.push(intents, armatures, counters, armatureAnimations, armatureSlots, springs, this.interactiveSystem);
    }
}
exports.PixiEcsApp = PixiEcsApp;
