"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.placeInRect = exports.hsplitRect = exports.vsplitRect = exports.padRect = void 0;
function padRect(rect, pad) {
    return rect.map((n, i) => n + pad[i]);
}
exports.padRect = padRect;
function vsplitRect(rect, dx) {
    const left = rect.slice();
    const right = rect.slice();
    left[2] = dx;
    right[0] += dx;
    return { left, right };
}
exports.vsplitRect = vsplitRect;
function hsplitRect(rect, dy) {
    const top = rect.slice();
    const bottom = rect.slice();
    top[3] = dy;
    bottom[1] += dy;
    return { top, bottom };
}
exports.hsplitRect = hsplitRect;
function placeInRect(rect, i, total, w, h) {
    const dw = rect[2] - w;
    const dh = rect[3] - h;
    const [x, y] = rect;
    return {
        x: { value: x + w / 2 + dw * (i + .5) / total },
        y: { value: y + h / 2 + dh * (i + .5) / total },
        z: { value: i }
    };
}
exports.placeInRect = placeInRect;
