interface ENV {
    REACT_APP_PARSE_SDK: string;
    REACT_APP_PARSE_URL: string;
    REACT_APP_PARSE_APP: string;
}
const {
    REACT_APP_PARSE_APP,
    REACT_APP_PARSE_SDK,
    REACT_APP_PARSE_URL
} = process.env as unknown as ENV;

const parse = REACT_APP_PARSE_SDK === 'parse' ? require('parse') :
    require('parse/node');

export const Parse = parse as typeof import('parse');
Parse.initialize(REACT_APP_PARSE_APP);
Parse.serverURL = REACT_APP_PARSE_URL;

export const sdk = REACT_APP_PARSE_SDK;
