import React, { Fragment, useEffect, useMemo, useReducer } from 'react';
import { GameRoomController, useLiveMessages, Parse } from "../../services/parse";
import { PortalContainer, usePopup } from './popup';
import { Avatar } from './user';

export function useUpdate<G>(c: GameRoomController<G>) {
    const [, update] = useReducer(i => i + 1, 0);
    useEffect(() => {
        c.addEventListener('update', update);
        return () => {
            c.removeEventListener('update', update);
        }
    }, [c, update]);
}

function RoomHook<G>(props: { controller: GameRoomController<G> }) {
    const c = props.controller;
    const messages = useLiveMessages(c.room!.id);
    useEffect(() => {
        c.receiveLiveMessages(messages);
    }, [c, messages, messages.length]);
    return null;
}

export function GameResetButton<G>(props: { controller: GameRoomController<G> }) {
    const c = props.controller;
    return useMemo(() => <button children="新游戏" onClick={c.sendSetup} />, [c]);
}

export function GameLogButton<G>(props: { controller: GameRoomController<G> }) {
    const c = props.controller;
    const popup = usePopup();
    const dialog = popup.exist() && <PortalContainer active={popup.active()} >
        <div className="full flex dimmed" onClick={() => popup.close()} >
            <div className="collapse flex paper flex-col">
                <pre>
                    {c.live.eventlog()}
                </pre>
            </div>
        </div>
    </PortalContainer>;
    const log = useMemo(() => <button children="日志" onClick={() => popup.open()} />, [popup]);
    return <Fragment>
        {dialog}{log}
    </Fragment>
}

export function GamePrompt<G>(props: { controller: GameRoomController<G> }) {
    const c = props.controller;
    useUpdate(c);

    const hook = c.room && <RoomHook controller={c} />
    const prompt = <span className="prompt flex"><FilterPrompt room={c.room} children={c.live.prompt()} /></span>
    const error = <span className="prompt flex">{c.live.error}</span>
    return <Fragment>{hook}{prompt}{error}</Fragment>
}

export function GamePassButton<G>(props: { controller: GameRoomController<G> }) {
    return <button children="让过" onClick={() => props.controller.sendIntent({
        intent: 'pass'
    })} />
}

export function GameControllerBar<G>(props: { controller: GameRoomController<G>, children?: any }) {
    return <div className="gamebar flex flex-wrap" style={{ flex: 1 }}>
        <span style={{ flex: 1 }}><GamePrompt controller={props.controller} /></span>
        {props.children}
    </div>
}

function PlayerId(props: { playerId: string, room: Parse.Object }) {
    const self = Parse.User.current();
    const p = props.playerId;
    const i = props.room.get('users').indexOf(p);
    return <Avatar playerId={p} key={p} className={`small p${i + 1} ${self && self.id === p ? 'self' : ''}`} />
}

function FilterPrompt(props: { children: string, room?: Parse.Object }) {
    if (!props.room)
        return <span>{props.children}</span>;
    const users = props.room.get('users') as string[];
    for (const user of users) {
        const i = props.children.indexOf(user);
        if (i >= 0) {
            return <Fragment>
                <FilterPrompt children={props.children.slice(0, i)} room={props.room} />
                <PlayerId playerId={user} room={props.room} />
                <FilterPrompt children={props.children.slice(i + user.length)} room={props.room} />
            </Fragment>
        }
    }
    return <span>{props.children}</span>;
}
