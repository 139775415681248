import React from "react";
import { G } from "@tabletop/noodle-game";
import { GameRoomController } from "../../services/parse";
import "./app.css";
import { ControllerTestGameState, InteractiveTestGameState, RoomControllerState, TestGameState } from "./configurations";
import { GameTable } from "./playmat";

export * from "./help";

export function NoodleDemo() {
    return <GameTable>
        <TestGameState/>
    </GameTable>
}

export function NoodlePlayableDemo() {
    return <GameTable>
        <InteractiveTestGameState/>
    </GameTable>
}

export function NoodleControllerDemo() {
    return <GameTable>
        <ControllerTestGameState/>
    </GameTable>
}

export function NoodleRoomController(props: {controller: GameRoomController<G>}) {
    return <GameTable>
        <RoomControllerState {...props}/>
    </GameTable>
}
