"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SpringSystem = exports.TargetSpring = exports.Spring = void 0;
const system_1 = require("./system");
class Spring {
    constructor(tension = 0.0027, friction = .012, position = 0, velocity = 0) {
        this.tension = tension;
        this.friction = friction;
        this.position = position;
        this.velocity = velocity;
    }
    copy(other) {
        this.tension = other.tension;
        this.friction = other.friction;
        this.position = other.position;
        this.velocity = other.velocity;
        return this;
    }
    clone() {
        return new Spring().copy(this);
    }
    acc(inputPos) {
        const dist = inputPos - this.position;
        const spring = dist * this.tension;
        const damp = -this.velocity * this.friction;
        return spring + damp;
    }
    step(inputPos, step = 1) {
        const acc = this.acc(inputPos);
        this.velocity += acc * step;
        this.position += this.velocity;
    }
    still(threshold = 0.01) {
        return Math.abs(this.velocity) < threshold;
    }
}
exports.Spring = Spring;
class TargetSpring extends Spring {
    constructor() {
        super(...arguments);
        this.target = 0;
    }
    stepTarget(step = 1) {
        this.step(this.target, step);
    }
}
exports.TargetSpring = TargetSpring;
class SpringSystem extends system_1.System {
    constructor(name) {
        super(name);
        this.springs = {};
        this.props = {};
        this.onTick = (delta) => {
            for (const ekey in this.springs) {
                for (const skey in this.springs[ekey]) {
                    const spring = this.springs[ekey][skey];
                    spring.stepTarget(delta);
                    this.props[ekey][skey] = spring.position;
                }
                this.onUpdate(ekey, this.props[ekey]);
            }
        };
    }
    onCreated(ekey, val) {
        this.springs[ekey] = {};
        this.props[ekey] = {};
        for (const key in val) {
            const spring = new TargetSpring();
            this.springs[ekey][key] = spring;
            const { init } = val[key];
            (init !== undefined) && (spring.position = init);
        }
        this.onApply(ekey, val);
        this.onTick(16);
    }
    onApply(ekey, val) {
        for (const key in val) {
            const spring = this.springs[ekey][key];
            const { tension, friction, value } = val[key];
            tension && (spring.tension = tension);
            friction && (spring.friction = friction);
            (value !== undefined) && (spring.target = value);
        }
    }
    onDestroyed(ekey) {
        delete this.springs[ekey];
        delete this.props[ekey];
    }
    onUpdate(_ekey, _props) {
    }
}
exports.SpringSystem = SpringSystem;
