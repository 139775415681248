"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.gameEffect = exports.gameEvent = exports.card = exports.game = exports.player = exports.deck = void 0;
const game_runner_1 = require("@tabletop/game-runner");
function deck(...draw) {
    return {
        draw, discard: [], pick: []
    };
}
exports.deck = deck;
function player(name) {
    return { name, hand: [], bowls: [[], [], []] };
}
exports.player = player;
function game(players) {
    return {
        players: players.map(player),
        ingredients: deck(),
        flavors: { ...deck(), bargain: [] },
        customers: deck(),
        customerDices: [],
        customerOwner: ['', '', ''],
    };
}
exports.game = game;
function card(key, name, type, params) {
    return { key, name, type, ...params };
}
exports.card = card;
function gameEvent(type, desc, params) {
    return game_runner_1.event(type, desc, params);
}
exports.gameEvent = gameEvent;
function gameEffect(type, desc, params) {
    return game_runner_1.effect(type, desc, params);
}
exports.gameEffect = gameEffect;
