"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.headEvent = exports.bowlScore = exports.playerView = exports.playerEntities = void 0;
const cards_1 = require("./cards");
function playerEntities(g, player) {
    const entities = {};
    if (!g.players)
        return entities;
    const p = g.players.find(p => p.name === player) || g.players[0];
    function updateList(deck, zone, intentRoles, flip, shrink, order = 0, total = 0) {
        for (let i = flip ? Math.max(0, deck.length - 3) : 0; i < deck.length; i++) {
            entities[deck[i]] = {
                type: 'card',
                zone,
                total: total + deck.length,
                order: order + i,
                intentRoles,
                armature: cards_1.cards.all[deck[i]].type,
                cardface: cards_1.cards.all[deck[i]].name,
                flip,
                shrink
            };
        }
    }
    updateList(g.ingredients.draw, 'ingredients_draw', { draw_ingredient: 'draw' }, true, false);
    updateList(g.ingredients.discard, 'ingredients_discard', {}, false, true);
    updateList(g.ingredients.pick, 'ingredients_pick', { pick_ingredient: 'pick' }, false, false);
    const flavors = g.flavors.pick.concat(g.flavors.bargain.slice(-1));
    updateList(flavors, 'flavors', { pick_flavor: 'pick' }, false, false);
    updateList(g.customers.pick, 'customers', { pick_customer: 'customer' }, false, false);
    for (let i = 0; i < 3; i++) {
        const e = entities[g.customers.pick[i]];
        e.dices = g.customerDices[i].slice();
        e.owner = g.players.findIndex(p => p.name == g.customerOwner[i]);
    }
    const handi = [];
    const handf = [];
    p.hand.forEach(c => cards_1.cards.all[c].type == '食材' ? handi.push(c) : handf.push(c));
    handi.sort(cards_1.compareCards);
    updateList(handi, 'hand', { play_ingredient: 'hand' }, false, false, 0, handf.length);
    updateList(handf, 'hand', { play_flavor: 'hand' }, false, false, handi.length, handi.length);
    for (let i = 0; i < 4; i++) {
        const zone = `player${i + 1}`;
        const player = g.players[i];
        if (!player)
            continue;
        let customers = 0;
        for (let i = 0; i < 3; i++) {
            if (g.customerOwner[i] == player.name)
                for (const dice of g.customerDices[i])
                    customers += dice;
        }
        entities[zone] = {
            type: 'player',
            zone,
            bowls: player.bowls.map((bowl, j) => [
                bowlScore(g, { player: player.name, bowl: j }),
                ...bowl.map(i => {
                    const card = cards_1.cards.all[i];
                    if (card.type !== '食材')
                        return 4;
                    if (card.name == '红油')
                        return 3;
                    if (card.subtype == '佐料')
                        return 2;
                    if (card.subtype == '素菜')
                        return 1;
                    return 0;
                })
            ]),
            hand: player.hand.length,
            customers
        };
    }
    for (let i = 0; i < 3; i++) {
        const zone = i == 0 ? 'bowl1' : i == 1 ? 'bowl2' : 'bowl3';
        updateList(p.bowls[i], zone, {}, false, true);
        entities[`drop_bowl_${i}`] = {
            type: 'dropAction',
            zone,
            intentRoles: {
                pick_flavor: 'bowl',
                play_flavor: 'bowl',
                play_ingredient: 'bowl',
            },
        };
        entities[`counter_bowl_${i}`] = {
            type: 'counter',
            zone,
            value: bowlScore(g, { player, bowl: i })
        };
    }
    entities['drop_hand'] = {
        type: 'dropAction',
        zone: 'hand',
        intentRoles: {
            pick_ingredient: 'hand',
            pick_customer: 'hand',
            draw_ingredient: 'hand'
        },
    };
    return entities;
}
exports.playerEntities = playerEntities;
function playerView(g, player) {
    const ingredients = {
        draw: g.ingredients.draw.length,
        discard: g.ingredients.discard.length,
        pick: g.ingredients.pick.slice(),
    };
    const customers = {
        draw: g.customers.draw.length,
        discard: g.customers.discard.length,
        pick: g.customers.pick.slice(),
    };
    const flavors = {
        draw: g.flavors.draw.length,
        discard: g.flavors.discard.length,
        pick: g.flavors.pick.slice(),
    };
    const players = g.players.map(p => {
        return {
            name: p.name,
            hand: p.hand.length,
            bowls: p.bowls.slice(),
        };
    });
    const p = g.players.find(p => p.name === player);
    const hand = p && p.hand.slice();
    return {
        ingredients, customers, flavors, players, hand
    };
}
exports.playerView = playerView;
function bowlScore(g, s) {
    if (!g.players)
        return 0;
    return cards_1.score(g.players.find(p => p.name === s.player).bowls[s.bowl])
        .map(p => p[1]).reduce((a, b) => a + b, 0);
}
exports.bowlScore = bowlScore;
function headEvent(_g, _, { stack }) {
    return { ...stack.head() };
}
exports.headEvent = headEvent;
