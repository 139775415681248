"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ephemeralSelf = exports.withEphMap = exports.createEphProxy = void 0;
function createEphProxy(map, store) {
    const ctx = {};
    const ephs = {};
    for (const key in map) {
        Object.defineProperty(ctx, key, {
            get() {
                if (!ephs[key]) {
                    ephs[key] = map[key].onCreate(store[key]);
                }
                return ephs[key];
            }
        });
    }
    return {
        ctx,
        setStore(newStore) {
            store = newStore;
        },
        cleanUp() {
            for (const key in ephs) {
                store[key] = map[key].onDestroy(ephs[key]);
                delete ephs[key];
            }
        }
    };
}
exports.createEphProxy = createEphProxy;
function withEphMap(map, store, cb) {
    const { ctx, cleanUp } = createEphProxy(map, store);
    try {
        return cb(ctx);
    }
    finally {
        cleanUp();
    }
}
exports.withEphMap = withEphMap;
function ephemeralSelf(thing) {
    let saved = undefined;
    return {
        onCreate(other) {
            saved = other;
            return thing;
        },
        onDestroy() {
            const daSaved = saved;
            saved = undefined;
            return daSaved;
        }
    };
}
exports.ephemeralSelf = ephemeralSelf;
