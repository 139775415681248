import EventTarget from '@ungap/event-target';
import { GameInstance, Intent, Rev } from "@tabletop/game-runner";
import { sendMessage } from "./room";
import { Parse } from "../../services/parse";

export class Version<G> {
    constructor(public game: GameInstance<G>, public localPlayerId = '你') { }

    version = { game: {} as G, store: { random: { seed: 'blah' } } as any } as Rev<G>;
    error = '';
    intents = [] as Intent[];

    onIntent = (i: Intent, autoresolve = true): boolean => {
        let ok = true;
        try {
            this.version = this.game.applyIntent(this.version, i);
        } catch (e) {
            this.error = (e as Error).message;
            ok = false;
        }

        if (ok && autoresolve) {
            while (this.onIntent({ intent: 'resolve' }, false));
            this.intents.push(i);
        }

        return ok;
    }

    rebase(base: Version<G>) {
        const intents = this.intents.slice(base.intents.length);
        base.intents.length = 0;
        this.copy(base);
        for (const i of intents) {
            this.onIntent(i);
        }
    }

    copy(other: Version<G>) {
        this.version = other.version;
        this.error = other.error;
        this.intents = other.intents.slice();
        this.localPlayerId = other.localPlayerId;
    }

    clone() {
        const nv = new Version(this.game);
        nv.copy(this);
        return nv;
    }

    eventlog() {
        return this.game.select(this.version, 'eventlog');
    }

    prompt() {
        return this.game.select(this.version, 'prompt');
    }

    authorize(intent: Intent) {
        if (this.localPlayerId === '你') return true;
        return this.game.select(this.version, 'authorize', intent);
    }
}

export class GameRoomController<G> extends EventTarget {
    updateEvent = new Event('update');
    resolved: Version<G>;
    live: Version<G>;

    constructor(public game: GameInstance<G>, public room?: Parse.Object) {
        super();
        this.resolved = new Version(game);
        this.live = new Version(game);
    }

    timeout = 0;
    update() {
        if (this.timeout) return;
        this.timeout = setTimeout(() => {
            this.dispatchEvent(this.updateEvent);
            this.timeout = 0;
        }, 0) as unknown as number;
    }

    sendIntent = (i: Intent) => {
        if (this.room) {
            sendMessage(this.room.id, 'intent', JSON.stringify(i));
        }
        (i as any).playerId = this.live.localPlayerId;
        this.live = this.receiveIntent(i, this.live);
        this.update();
    }

    sendSetup = () => {
        this.sendIntent({
            intent: 'setup',
            players: this.room ? this.room.get('users') as string[] : ['你'],
            store: {
                random: {
                    seed: Math.random().toString(16).slice(-8)
                }
            }
        } as Intent);
    }

    receiveIntent(intent: Intent, version: Version<G>) {
        if (intent.intent === 'setup') {
            const { players, store } = intent as Intent & { players: string[], store: any };
            const u = Parse.User.current();
            const localPlayerId = u && players.indexOf(u.id) > -1 ? u.id : players[0];

            version = new Version(this.game, localPlayerId);
            version.version.store = store;
            version.onIntent({ intent: 'resolve' });
            version.onIntent(intent);
        } else {
            if (!version.authorize(intent)) {
                return version;
            }
            version.onIntent(intent);
        }
        return version;
    }

    received = 0;
    receiveLiveMessages = (messages: Parse.Object[]) => {
        while (this.received < messages.length) {
            const msg = messages[this.received++];
            if (msg.get('type') !== 'intent') {
                continue;
            }

            try {
                const intent = JSON.parse(msg.get('content')) as { intent: string, store: any, playerId: string, players: string[] };
                intent.playerId = msg.get('userId');
                this.resolved = this.receiveIntent(intent, this.resolved);
                this.live.rebase(this.resolved);
                this.update();
            } catch (e) {
                console.error('invalid intent in messages', e);
            }
        }
    }
}
