import React, { createContext, useContext, useEffect, useMemo } from "react";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import { ensureAccount, enterGameRoom, useLiveRoomObject } from "../services/parse";

export function GameRoomRoute(props: { children?: any }) {
    return <Routes>
        <Route path="/" element={<CreateRoom />} />
        <Route path=":roomId" element={<LoadRoom children={props.children} />} />
    </Routes>
}

function CreateRoom() {
    const navigate = useNavigate();
    useEffect(function() {
        ensureAccount().then(() => {
            return enterGameRoom()
        }).then(id => {
            console.log('created a game at', id)
            navigate(id);
        });
    }, [navigate])
    return null;
}

export const roomCtx = createContext({} as {
    roomId?: string,
    room?: Parse.Object,
});

function LoadRoom(props: { children?: any }) {
    const params = useParams() as { roomId: string };
    const { roomId } = params;

    const room = useLiveRoomObject(roomId);

    const ctx = useMemo(function() {
        return { roomId, room: room || undefined };
    }, [roomId, room]);

    return <roomCtx.Provider value={ctx} children={props.children} />
}

export function useRoomRoute() {
    return useContext(roomCtx);
}
