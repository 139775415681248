"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.takeCustomer = exports.throwCustomerDices = exports.pick = exports.deal = exports.shuffle = exports.setup = void 0;
const game_runner_1 = require("@tabletop/game-runner");
const cards_1 = require("./cards");
const NoodleGame = __importStar(require("./schema"));
function setup(_, e) {
    const { players } = e;
    const game = NoodleGame.game(players || ['red', 'blue']);
    game.flavors.draw.push(...cards_1.cards.flavors);
    game.flavors.bargain.push(...cards_1.cards.bargains);
    game.ingredients.draw.push(...cards_1.cards.ingredients);
    game.customers.draw.push(...cards_1.cards.customers);
    return game;
}
exports.setup = setup;
function shuffle(g, e, ctx) {
    const { path } = e;
    const deck = game_runner_1.access.find(g, ...path).value;
    const newDeck = [...deck];
    ctx.random.shuffle(newDeck);
    game_runner_1.access.set(g, newDeck, ...path);
}
exports.shuffle = shuffle;
function deal(g, e) {
    const { from, to, n } = e;
    const fromdeck = game_runner_1.access.find(g, ...from).value;
    const todeck = game_runner_1.access.find(g, ...to).value;
    todeck.push(...fromdeck.splice(fromdeck.length - (n || 1), n));
}
exports.deal = deal;
function pick(g, e) {
    const { from, to, items } = e;
    const fromdeck = game_runner_1.access.find(g, ...from).value;
    const todeck = game_runner_1.access.find(g, ...to).value;
    todeck.push(...items);
    game_runner_1.access.set(g, fromdeck.filter(item => items.indexOf(item) === -1), ...from);
}
exports.pick = pick;
function throwCustomerDices(g, _e, ctx) {
    const dices = [];
    for (let i = 0; i < 3; i++) {
        const cid = g.customers.pick[i];
        const customer = cards_1.cards.all[cid];
        const customerDices = [];
        dices.push(customerDices);
        for (let j = 0; j < customer.throws; j++) {
            customerDices.push(ctx.random.dice(6));
        }
        customerDices.sort((a, b) => a - b);
        customerDices.length = customer.dices;
    }
    g.customerDices = dices;
}
exports.throwCustomerDices = throwCustomerDices;
function takeCustomer(g, e) {
    const { i, player } = e;
    g.customerOwner[i] = player;
}
exports.takeCustomer = takeCustomer;
