"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EcsCtx = void 0;
const collection_1 = require("./collection");
const system_1 = require("./system");
__exportStar(require("./collection"), exports);
__exportStar(require("./system"), exports);
__exportStar(require("./spring"), exports);
class EcsCtx {
    constructor() {
        this.world = new collection_1.World();
        this.systems = [];
        this.ticking = false;
        this.prevFrame = -1;
        this.tick = (total) => {
            if (!this.ticking) {
                this.prevFrame = -1;
                return;
            }
            const delta = this.prevFrame < 0 ? 16 : (total - this.prevFrame);
            this.prevFrame = total;
            for (const one of this.systems) {
                one.onTick(delta);
            }
            requestAnimationFrame(this.tick);
        };
    }
    update(doc) {
        const { world, systems } = this;
        for (const p of world.makePatches(doc)) {
            world.applyPatch(p);
            system_1.applySystemPatch(p, systems, world);
        }
    }
    start() {
        if (this.ticking)
            return;
        this.ticking = true;
        requestAnimationFrame(this.tick);
    }
    stop() {
        this.ticking = false;
    }
}
exports.EcsCtx = EcsCtx;
