import React, { useMemo } from "react";
import { createPortal } from 'react-dom';
import "./popup.css";

export function usePopup() {
    const [, setShow] = React.useState(0);
    return useMemo(function() {
        let timeout = 0 as any;
        let current = 0 as any;

        function open() {
            timeout && clearTimeout(timeout);
            timeout = 0;
            current = 2;
            setShow(2);
        }

        function close() {
            current = 1;
            setShow(1);
            timeout && clearTimeout(timeout);
            timeout = setTimeout(kill, 500);
        }

        function kill() {
            timeout = 0;
            current = 0;
            setShow(0);
        }

        function exist() {
            return current >= 1;
        }

        function active() {
            return current >= 2;
        }
        return { open, close, exist, active };
    }, []);
}

export function makePortalDom() {
    let id = 'portal-' + Math.random().toString(16).slice(-8);
    while (document.querySelector('#' + id)) {
        id = 'portal-' + Math.random().toString(16).slice(-8);
    }
    const dom = document.createElement('div');
    dom.id = id;
    return dom;
}
export function PortalContainer(props: { active: boolean, onclick?: () => void, children?: any }) {

    const { onclick } = props;
    const dom = React.useMemo(makePortalDom, []);

    React.useLayoutEffect(function() {
        document.body.append(dom);
        return function() {
            dom.remove();
        }
    }, [dom]);

    React.useLayoutEffect(function() {
        dom.className = props.active ? 'portal-container active' : 'portal-container';
    }, [dom, props.active]);

    React.useLayoutEffect(function() {
        dom.onclick = e => {
            if (e.target instanceof HTMLElement) {
                if (e.target.parentElement === dom)
                    onclick && onclick();
            }
        }
        return function() {
            dom.onclick = null;
        }
    }, [dom, onclick]);

    return createPortal(props.children, dom);
}
