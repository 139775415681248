"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.funclib = exports.FunctionLibrary = void 0;
class FunctionLibrary {
    constructor() {
        this.map = {};
        this.namespaces = [];
    }
    call(key, ...args) {
        for (const prefix of this.prefixes()) {
            const cb = this.map[prefix + key];
            if (cb)
                return cb(...args);
        }
        throw new Error(`didnt find function '${key}' in lib`);
    }
    addAll(map) {
        for (const key in map) {
            this.map[key] = map[key];
        }
    }
    onCreate(t) {
        this.namespaces = t || [];
        return this;
    }
    onDestroy(t) {
        return t.namespaces;
    }
    *prefixes() {
        for (const ns of this.namespaces) {
            yield ns;
        }
        yield "";
    }
}
exports.FunctionLibrary = FunctionLibrary;
exports.funclib = new FunctionLibrary();
