import React, { Fragment, useEffect, useMemo, useReducer, useState } from "react";
import { Intent } from "tabletop-game";
import { G, game } from "@tabletop/noodle-game";
import { GameRoomController } from "../../services/parse";
import "./app.css";
import { GameController, GameState, GameStatics, GameUI } from "./state";

export function RoomControllerState(props: {controller: GameRoomController<G>}) {
    const c = props.controller;
    const [, update] = useReducer(i => i + 1, 0);
    useEffect(() => {
        c.addEventListener('update', update);
        return () => {
            c.removeEventListener('update', update);
        }
    }, [c, update]);

    return <Fragment>
        <GameUI s={c.live.version} onIntent={c.sendIntent} playerId={c.live.localPlayerId}/>
        <GameState state={c.live.version.game} playerId={c.live.localPlayerId}/>
    </Fragment>
}

export function ControllerTestGameState() {
    const controller = useMemo(() => new GameController(), []);

    const [, update] = useReducer(i => i + 1, 0);
    useEffect(function() {
        controller.addEventListener('update', update);
        controller.onIntent({intent: 'pass'});
        return function(){
            controller.removeEventListener('update', update);
        }
    }, [controller, update]);


    return <Fragment>
        <GameStatics s={controller.version} error={controller.error} onIntent={controller.onIntent} />
        <GameState state={controller.version.game} />
    </Fragment>
}

export function InteractiveTestGameState() {
    const [s, setState] = useState({ game: {} as G, store: { random: { seed: 'blah' } } as any });
    const [error, setError] = useState('');

    useEffect(function() {
        setState(game.applyIntents(
            { game: {} as G, store: { random: { seed: 'blah' } } as any }, [
            { intent: 'pass' },
            { intent: 'setup', players: ['你'] },
            { intent: 'pass' },
            { intent: 'pass' },
        ]));
    }, []);

    const onIntent = (i: Intent) => {
        try {
            let ns = game.applyIntent(s, i);
            setState(ns);
            while (game.select(ns, 'headEvent').event !== 'play') {
                ns = game.applyIntent(ns, { intent: 'pass' });
                setState(ns);
            }
            setError('');
        } catch (e) {
            setError(e.message);
        }
    }

    return <Fragment>
        <GameStatics s={s} error={error} onIntent={onIntent} />
        <GameState state={s.game} />
    </Fragment>
}

export function TestGameState() {
    const [s, setState] = useState({ game: {} as G, store: { random: { seed: 'blah' } } as any });
    useEffect(function() {
        let ns = { game: {} as G, store: { random: { seed: 'blah' } } as any };

        const intents = [
            { intent: 'setup', players: ['red'] },
            { intent: 'pick_ingredient', pick: 'ING#22-煎蛋' },
            { intent: 'pass' },
            { intent: 'play_ingredient', hand: 'ING#17-臊子', bowl: 0 },
            { intent: 'play_ingredient', hand: 'ING#26-软浆叶', bowl: 0 },
            { intent: 'pass' },
            { intent: 'pick_flavor', pick: 'FLA#15-碗杂面', bowl: 0 },
            { intent: 'pick_ingredient', get pick() { return ns.game.ingredients.pick[2] } },
            { intent: 'pass' },
            { intent: 'draw_ingredient' },
            { intent: 'pick_ingredient', get pick() { return ns.game.ingredients.pick[2] } },
            { intent: 'pass' },
            { intent: 'pick_ingredient', get pick() { return ns.game.ingredients.pick[2] } },
            { intent: 'draw_ingredient' },
            { intent: 'pass' },
            { intent: 'pick_ingredient', get pick() { return ns.game.ingredients.pick[1] } },
            { intent: 'play_ingredient', get hand() { return ns.game.players[0].hand[3] }, bowl: 1 },
            { intent: 'play_ingredient', get hand() { return ns.game.players[0].hand[4] }, bowl: 1 },
            { intent: 'pass' },
            { intent: 'play_ingredient', get hand() { return ns.game.players[0].hand[2] }, bowl: 1 },
            { intent: 'play_ingredient', get hand() { return ns.game.players[0].hand[5] }, bowl: 1 },
            { intent: 'pass' },
            { intent: 'play_ingredient', get hand() { return ns.game.players[0].hand[2] }, bowl: 1 },
            { intent: 'play_flavor', get hand() { return ns.game.players[0].hand[1] }, bowl: 1 },
            { intent: 'pass' },
            { intent: 'play_ingredient', get hand() { return ns.game.players[0].hand[0] }, bowl: 2 },
            { intent: 'play_ingredient', get hand() { return ns.game.players[0].hand[0] }, bowl: 2 },
            { intent: 'pass' },
            { intent: 'play_ingredient', get hand() { return ns.game.players[0].hand[0] }, bowl: 2 },
            { intent: 'pick_flavor', get pick() { return ns.game.flavors.pick[1] }, bowl: 2 },
        ];

        (async function() {
            for (let i = 0; i < intents.length; i++) {
                await new Promise(resolve => setTimeout(resolve, 500));
                const head = game.select(ns, 'headEvent').event;

                try {
                    if (head === 'play' || head === 'setup')
                        ns = game.applyIntent(ns, { ...intents[i] });
                    else {
                        i--;
                        ns = game.applyIntent(ns, { intent: 'pass' });
                    }
                } catch (e) {
                    console.error(game.select(ns, 'eventstack'));
                }
                setState(ns);
            }
            console.log(game.select(ns, 'eventstack'));
        })();

    }, []);
    return <GameState state={s.game} />
}
