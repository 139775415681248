import EventTarget from "@ungap/event-target";
import React, { Fragment, useEffect, useState } from "react";
import { Collection, EventTargetComponent, Intent, LayoutAnchor, PixiTransform, Rev } from "tabletop-game";
import { G, game } from "@tabletop/noodle-game";
import "./app.css";
import { anchors, CardTarget, GameButton, GameDropActions, GameNumber, GameText } from "./playmat";

type Targets = { [key: string]: EventTargetComponent<any> };
function getTargets(game: G, prev: Targets, playerId?: string) {
    const current = {} as Targets;
    if (!game.players) return current;

    function updateList(deck: string[], anchor: LayoutAnchor, open = true) {
        for (let i = open ? 0 : Math.max(0, deck.length - 3); i < deck.length; i++) {
            const cardkey = deck[i];
            const props = {
                cardkey, index: i, total: deck.length, anchor
            };

            const cardTarget = prev[cardkey] || new CardTarget(props);
            current[cardkey] = cardTarget;

            const shouldUpdate = cardTarget.shouldUpdate(props, cardTarget.props);
            cardTarget.props = props;
            shouldUpdate && cardTarget.update();
        }
    }
    function updateDeck(open: boolean, ...path: (string | number)[]) {
        let obj = game as any;
        let aobj = anchors as any;
        for (const key of path) {
            obj = obj[key];
            aobj = aobj[key];
        }

        const deck = obj as string[];
        const anchor = aobj as LayoutAnchor;
        updateList(deck, anchor, open);
    }

    updateDeck(false, 'ingredients', 'draw');
    updateDeck(true, 'ingredients', 'discard');
    updateDeck(true, 'ingredients', 'pick');

    updateDeck(false, 'flavors', 'draw');
    updateDeck(true, 'flavors', 'discard');
    updateDeck(true, 'flavors', 'pick');
    updateDeck(false, 'flavors', 'bargain');

    updateDeck(false, 'customers', 'draw');
    updateDeck(true, 'customers', 'discard');
    updateDeck(true, 'customers', 'pick');

    const p = game.players.find(p => p.name === playerId) || game.players[0];
    updateList(p.hand, anchors.player.hand);
    for (let i = 0; i < 3; i++) {
        updateList(p.bowls[i], anchors.player.bowls[i]);
    }

    return current;
}

export function GameState(props: { state: G, playerId?: string }) {
    const { state, playerId } = props;
    const [targets, setTargets] = useState([{}, {}] as [G, Targets]);

    useEffect(function() {
        const [prevState, prevTargets] = targets;
        if (prevState === state) return;

        const current = getTargets(state, prevTargets, playerId);
        setTargets([state, current]);
    }, [state, targets, playerId]);

    return <Collection targets={targets[1]} />
}

export function GameUI(props: { s: Rev<G>, onIntent: (i: Intent) => void, playerId: string}) {
    const { s, onIntent, playerId } = props;
    return <Fragment>
        <GameNumber value={game.select(s, 'bowlScore', { player: playerId, bowl: 0 })}>
            <PixiTransform x={-1000} y={850} />
        </GameNumber>
        <GameNumber value={game.select(s, 'bowlScore', { player: playerId, bowl: 1 })}>
            <PixiTransform x={0} y={850} />
        </GameNumber>
        <GameNumber value={game.select(s, 'bowlScore', { player: playerId, bowl: 2 })}>
            <PixiTransform x={1000} y={850} />
        </GameNumber>
        <GameDropActions onIntent={onIntent} />
    </Fragment>
}
export function GameStatics(props: { s: Rev<G>, onIntent: (i: Intent) => void, error: string }) {
    const { s, onIntent, error } = props;
    return <Fragment>
        <GameButton text="让过" onClick={() => onIntent({ intent: 'pass' })}>
            <PixiTransform x={-2000} y={-1000} sx={2} sy={2} />
        </GameButton>
        <GameButton text="日志" onClick={() => logToWindow(game.select(s, 'eventlog'))}>
            <PixiTransform x={-1500} y={-1000} sx={2} sy={2} />
        </GameButton>
        <GameText text={game.select(s, 'prompt')}>
            <PixiTransform x={0} y={-1000} sx={2} sy={2} />
        </GameText>
        <GameText text={error}>
            <PixiTransform x={0} y={-900} sx={2} sy={2} />
        </GameText>
        <GameUI s={s} onIntent={onIntent} playerId='你'/>
    </Fragment>
}

function logToWindow(msg: string) {
    const data = `<pre>${msg}</pre>`;
    const myWindow = window.open("data:text/html," + encodeURIComponent(data),
        "_blank", "width=400,height=100");
    myWindow!.document.write(data);
    myWindow!.focus();
}

export class GameController extends EventTarget {
    seed?: string;

    version = { game: {} as G, store: { random: { seed: 'blah' } } as any } as Rev<G>;
    error = '';
    updateEvent = new Event('update');

    onIntent = (i: Intent, autopass = true): boolean => {
        let ok = true;
        try {
            this.version = game.applyIntent(this.version, i);
        } catch (e) {
            this.error = (e as Error).message;
            ok = false;
        }

        if (ok && autopass) {
            while (this.onIntent({ intent: 'pass' }, false));
        }

        if(autopass)this.update();
        return ok;
    }

    update(){
        this.dispatchEvent(this.updateEvent);
    }
}
