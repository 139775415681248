"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.setSlotTexture = exports.setAnimationFlag = exports.hasArmature = exports.createArmatureDisplay = exports.loadDragonBones = void 0;
const PIXI = __importStar(require("pixi.js"));
const db_1 = require("./db");
async function loadDragonBones(key, path) {
    const key_ske = key + '_ske';
    const key_tex = key + '_tex';
    const key_png = key + '_png';
    const res = await new Promise(resolve => {
        if (PIXI.Loader.shared.resources[key_ske]) {
            resolve(PIXI.Loader.shared.resources);
            return;
        }
        PIXI.Loader.shared
            .add(key_ske, path + key_ske + '.json')
            .add(key_tex, path + key_tex + '.json')
            .add(key_png, path + key_tex + '.png')
            .load((_, res) => resolve(res));
    });
    const skeleton = res[key_ske];
    const texture = res[key_tex];
    const png = res[key_png];
    const factory = db_1.PixiFactory.factory;
    factory.parseDragonBonesData(skeleton.data);
    factory.parseTextureAtlasData(texture.data, png.texture);
}
exports.loadDragonBones = loadDragonBones;
function createArmatureDisplay(armature, dbname) {
    const factory = db_1.PixiFactory.factory;
    const armatureDisplay = factory.buildArmatureDisplay(armature, dbname);
    return armatureDisplay;
}
exports.createArmatureDisplay = createArmatureDisplay;
function hasArmature(armature, dbname) {
    return !!db_1.PixiFactory.factory.getArmatureData(armature, dbname);
}
exports.hasArmature = hasArmature;
function setAnimationFlag(armature, animation, flag) {
    if (!armature.animation.hasAnimation(animation))
        return;
    const dir = flag ? 1 : -1;
    let anim = armature.animation.getState(animation);
    const fadeIn = !anim || (anim.isCompleted && anim.timeScale !== dir);
    if (fadeIn) {
        anim = armature.animation.fadeIn(animation, 0, 1, 0, animation);
        if (!anim)
            return;
        anim.resetToPose = false;
        anim.timeScale = dir;
    }
    if (anim.timeScale !== dir) {
        anim.timeScale = dir;
        //@ts-ignore
        anim._time += dir * anim.totalTime;
    }
    return anim;
}
exports.setAnimationFlag = setAnimationFlag;
function setSlotTexture(armature, slot, texture) {
    const save = armature.armature.getSlot(slot).display;
    const { width, height } = save.texture.frame;
    const t = new PIXI.Texture(texture, undefined, undefined, new PIXI.Rectangle(0, 0, width, height));
    save.texture = t;
}
exports.setSlotTexture = setSlotTexture;
