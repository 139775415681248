import React, { Fragment, useEffect, useState } from "react";
import { ensureAccount, enterGameRoom, exitGameRoom, Parse } from "../../services/parse";
import { Avatar } from "./user";
import "./room.css";
import { PortalContainer, usePopup } from "./popup";
import { LocationQRCode } from "./qrcode";

export function RoomBar(props: { room?: Parse.Object }) {
    const { room } = props;
    const popup = usePopup();

    return <Fragment>
        <div className="room-bar flex" onClick={() => popup.open()}>
            <RoomPlayers room={room} />
        </div>
        {popup.exist() && <PortalContainer active={popup.active()} onclick={() => popup.close()}>
            <div className="full flex dimmed">
                <div className="collapse paper flex flex-col">
                    <div className="room-bar flex">
                        <RoomSelf room={room} />
                        <RoomPlayers room={room} />
                        <RoomControls room={room} />
                    </div>
                    <a href={window.location.href} children={window.location.href} />
                    <LocationQRCode />
                </div>
            </div>
        </PortalContainer>}
    </Fragment>

}

const noroom = <span className="error">未找到房间</span>;
const noplayer = <span className="error">无玩家</span>;
const listplayers = (all: string[], you?: string) => <Fragment>
    {all.map((p, i) => <Avatar playerId={p} key={p}
        className={`small p${i + 1} ${you === p ? 'self' : ''}`} />)}
</Fragment>

function RoomPlayers(props: { room?: Parse.Object }) {
    const self = Parse.User.current();
    const players: string[] | undefined = props.room && props.room.get('users');
    return <div className="players">
        {!players ? noroom : !players.length ? noplayer :
            listplayers(players, self && self.id)}
    </div>
}

function RoomSelf(props: { room?: Parse.Object }) {
    const self = Parse.User.current();
    const players: string[] | undefined = props.room && props.room.get('users');

    const message = !players ? null : !self ? null :
        players.indexOf(self.id) < 0 ? <span className="warn">旁观中</span> :
            <span> 你是
                <Avatar playerId={self.id}
                    className={`small self p${1 + players.indexOf(self.id)}`} />
            </span>

    return <div className="self-player" style={{marginRight: '3em'}}>{message}</div>
}

function RoomControls(props: { room?: Parse.Object }) {
    const roomId = props.room && props.room.id;
    const [self, setSelf] = useState(Parse.User.current());
    const disabledEnter = !props.room || !self || props.room.get('users').indexOf(self.id) >= 0;
    const disabledExit = !props.room || !self || props.room.get('users').indexOf(self.id) < 0;
    useEffect(function() {
        ensureAccount().then(setSelf);
    }, []);
    return <div className="controls">
        <button className="enter" children="加入" disabled={disabledEnter} onClick={() => enterGameRoom(roomId)} />
        <button className="exit" children="退出" disabled={disabledExit} onClick={() => exitGameRoom(roomId!)} />
    </div>
}
