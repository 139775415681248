import * as PIXI from "pixi.js";
import { horizontalZones, PlayerEntity, verticalZones, ZoneRect } from "@tabletop/noodle-game";

function urlParam(pattern: RegExp) {
    const res = window.location.href.match(pattern);
    return res && res[1];
}
export const CELL = parseInt(urlParam(/cell=(\d+)/) || "0") || 450;
export const HORIZONTAL = !!urlParam(/(horizontal)/);

export function mapMap<A, B>(mapA: { [key: string]: A }, mapFunc: (a: A) => B) {
    const mapB = {} as { [key: string]: B };
    for (const key in mapA) {
        const a = mapA[key];
        const b = mapFunc(a);
        mapB[key] = b;
    }
    return mapB;
}
function zone2grid(n: number, isy = false) {
    return (n - (isy === HORIZONTAL ? 4.5 : 8)) * CELL;
}
export function zone2rect(zoneRect: { maxx: number, minx: number, maxy: number, miny: number }) {
    const x = zone2grid(zoneRect.minx, false);
    const y = zone2grid(zoneRect.miny, true);
    const w = zone2grid(zoneRect.maxx + 1, false) - x;
    const h = zone2grid(zoneRect.maxy + 1, true) - y;
    return { x, y, w, h };
}
function placeInRect(zone: ZoneRect, order: number, total: number, w = 2, h = 3) {
    w = Math.min(zone.maxx - zone.minx + 1, w);
    h = Math.min(zone.maxy - zone.miny + 1, h);
    const sx = zone.minx + w / 2;
    const sy = zone.miny + h / 2;
    const ex = zone.maxx - w / 2 + 1;
    const ey = zone.maxy - h / 2 + 1;
    const x = sx + (ex - sx) * (order + .5) / total;
    const y = sy + (ey - sy) * (order + .5) / total;
    const z = Math.round((x + y) * 20) + order;

    const xv = zone2grid(x, false);
    const yv = zone2grid(y, true);
    return {
        x: { value: xv, init: xv },
        y: { value: yv, init: yv },
        z: { value: z, init: z },
        r: { value: 0 }
    };
}

function makeZoneSprite(zoneName: keyof typeof horizontalZones, src: string) {
    const zones = HORIZONTAL ? horizontalZones : verticalZones;
    const sprite = new PIXI.Sprite(PIXI.Texture.from(src));
    const zoneRect = zones[zoneName];
    const { x, y, w, h } = zone2rect(zoneRect);
    sprite.position.set(x, y);
    sprite.width = w; sprite.height = h;
    return sprite;
}
function makeRectSprite(src: string, x: number, y: number, w: number, h: number) {
    const sprite = new PIXI.Sprite(PIXI.Texture.from(src));
    const rect = zone2rect({ minx: x, maxx: x + w - 1, miny: y, maxy: y + h - 1 });
    sprite.position.set(rect.x, rect.y);
    sprite.width = rect.w; sprite.height = rect.h;
    return sprite;
}
function makeTileSprite(src: string, x: number, y: number, w: number, h: number) {
    const sprite = new PIXI.TilingSprite(PIXI.Texture.from(src));
    const rect = zone2rect({ minx: x, maxx: x + w - 1, miny: y, maxy: y + h - 1 });
    sprite.position.set(rect.x, rect.y);
    sprite.width = rect.w; sprite.height = rect.h;
    sprite.tileScale.set(2, 2);
    return sprite;
}

export function* makeZoneSprites() {
    const zones = HORIZONTAL ? horizontalZones : verticalZones;
    yield makeRectSprite('db/小面/红桌.png', 0, 0, 9, 16);

    {
        const { maxx, maxy, minx, miny } = zones['player1'];
        for (let i = 0; i < 4; i++) {
            yield makeRectSprite('db/小面/木牌.png',
                minx + i, miny + .5, maxx - minx + 1, maxy - miny);
        }
    }
    // yield makeTileSprite('db/小面/红黄砖.png', 0, 6, 9, 3);
    // yield makeTileSprite('db/小面/折磨人的菱形.png', 0, 4, 9, 5);

    // yield makeZoneSprite('flavors', 'db/小面/点单区.png');
    // yield makeZoneSprite('ingredients_pick', 'db/小面/食材区.png');
    // yield makeZoneSprite('bowl1', 'db/小面/碗区.png');
    // yield makeZoneSprite('bowl2', 'db/小面/碗区.png');
    // yield makeZoneSprite('bowl3', 'db/小面/碗区.png');

}

const armatures = {
    食材: 'Ingredient2',
    点单: 'Flavor2',
    顾客: 'Customer2',
}

export function makeEntity(pe: PlayerEntity) {
    const { zone, type } = pe;
    const zoneRect = HORIZONTAL ? horizontalZones[zone] : verticalZones[zone];
    if (type === 'card') {
        const { armature, flip, shrink, cardface, total, order, intentRoles, dices, owner } = pe as PlayerEntity<'card'>;

        const transform = placeInRect(zoneRect, order, total);
        if (zone.startsWith('bowl'))
            transform.r.value = (order + .5 - total / 2) * 60 * Math.PI / 180 / total;

        const diceAnimations = {} as any;
        if(dices){
            for(let i = 0; i < dices.length; i ++){
                const slot = 'Dice' + (i+1);
                diceAnimations[slot] = 'db/小面/Dice' + dices[i] + '.png';
            }
            if(owner !== undefined && owner > -1)
                diceAnimations['头像-玩家'] = 'db/小面/头像-玩家' + (owner+1) + '.png'
        }
        return {
            armature: {
                bundle: '牌面动画',
                armature: armatures[armature as keyof typeof armatures]
            },
            armatureAnimations: {
                flip, shrink
            },
            armatureSlots: {
                牌面: 'db/小面/' + cardface + '.png', ...diceAnimations
            },
            interactive: {
                // enabled: Object.keys(intentRoles).length > 0,
                enabled: true,
                roles: intentRoles,
            },
            transform,
        }

    } else if (type === 'dropAction') {
        const { intentRoles } = pe as PlayerEntity<'dropAction'>;
        const { x, y, w, h } = zone2rect(zoneRect);
        return {
            dropzone: {
                roles: intentRoles,
                zone: [x + w / 2, y + h / 2, w, h]
            }
        }

    } else if (type === 'counter') {
        const { value } = pe as PlayerEntity<'counter'>;
        const transform = placeInRect(zoneRect, 0, 1);
        transform.x.value -= 60;
        transform.y.value += CELL * .88;
        transform.z.value += 100;

        return {
            armatureCounter: {
                armature: 'Character',
                bundle: '牌面动画',
                slot: 'Symbols',

                ux: 60, uy: 0, vx: 0, vy: 0,
                alphabet: '0123456789abcdABCDE',

                frames: 'E ' + value.toString()
            },
            transform
        }
    } else if (type === 'player') {
        const { bowls, hand, customers } = pe as PlayerEntity<'player'>;
        const score = bowls.map(bowl => bowl[0]).reduce((a, b) => a + b, 0) + customers;
        const transform = placeInRect(zoneRect, 0, 1, 2, 1);

        const pi = zone.charCodeAt(zone.length - 1) - '1'.charCodeAt(0);
        const p = String.fromCharCode(pi + 'a'.charCodeAt(0));

        transform.y.value += 300;
        transform.x.value -= 150;

        const bs = bowls.map(b => {
            let str = '';
            for (let i = 1; i < b.length; i++) {
                str += String.fromCharCode(b[i] + 65);
            }
            return str;
        });

        function pad(line: string, target: number) {
            return line.padStart(line.length / 2 + target / 2, ' ');
        }
        const lines = [
            pad(`E ${score}`, 6),
            pad(bs[2], 6),
            pad(bs[1], 6),
            pad(bs[0], 6),
            pad(` ${p} ${hand}`, 6)
        ];
        const frames = lines.join('\n');

        return {
            armatureCounter: {
                armature: 'Character',
                bundle: '牌面动画',
                slot: 'Symbols',

                ux: 60, uy: 0, vx: 0, vy: -150,
                alphabet: '0123456789abcdABCDE',

                frames
            },
            transform
        }
    }
}
