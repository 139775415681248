import { game } from "@tabletop/noodle-game";
import { hasArmature, loadDragonBones, PixiEcsApp } from "@tabletop/pixidb";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { GameCustomersHelp, GameFlavorsHelp, GameHelp, GameIngredientsHelp } from "../components/noodle";
import { PixiApp, RoomControllerState, useEcs } from "../components/noodle/app";
import { GameControllerBar, GameLogButton, GamePassButton, GameResetButton, PortalContainer, RoomBar, usePopup } from "../components/parse";
import { GameRoomController } from "../services/parse";
import { GameRoomRoute, useRoomRoute } from "./gameroom";


export function ECSRoutes() {
    return <Routes>
        <Route path="test" element={<PixiApp><TestDoc /></PixiApp>} />
        <Route path="playable/*" element={<GameRoomRoute children={<Playable />} />} />
    </Routes>
}

function GameMenu<G>(props: { controller: GameRoomController<G> }) {
    const c = props.controller;
    const popup = usePopup();
    const dialog = <PortalContainer active={popup.active()} onclick={() => popup.close()}>
        <div className="full flex dimmed">
            <div className="collapse paper flex flex-col" onClick={() => popup.close()}>
                <GameResetButton controller={c} />
                <br/>
                <GameLogButton controller={c} />
                <GameHelp/>
                <GameIngredientsHelp/>
                <GameFlavorsHelp/>
                <GameCustomersHelp/>
            </div>
        </div>
    </PortalContainer>;
    const button = useMemo(() => <button children="游戏" onClick={() => popup.open()} />, [popup]);
    return <Fragment>
        {dialog}{button}
    </Fragment>
}

function Playable() {
    const ctx = useRoomRoute();
    const { room } = ctx;
    const controller = useMemo(() => new GameRoomController(game, room), [room]);
    const [loaded, setLoaded] = useState(hasArmature('Ingredient2', '牌面动画'));
    useEffect(function() {
        if (loaded) {
            console.log('armature is loaded');
            return;
        }

        loadDragonBones('牌面动画', 'db/小面/').then(() => setLoaded(true));
    }, [loaded]);

    if (!room) return <span className="error"> 连接中……</span>;
    if (!loaded) return <span className="error">载入中……</span>;

    return <Fragment>
        <div className="navbar flex" style={{ position: 'relative', zIndex: 1 }}>
            <RoomBar room={room} />
            <GameControllerBar controller={controller}>
                <div className="flex flex-col">
                    <GameMenu controller={controller} />
                    <GamePassButton controller={controller} />
                </div>
            </GameControllerBar>
        </div>
        <PixiApp>
            <RoomControllerState controller={controller} />
        </PixiApp>
    </Fragment>
}

export function TestDoc() {
    const [loaded, setLoaded] = useState(hasArmature('Ingredient2', '牌面动画'));

    useEffect(function() {
        if (loaded) {
            console.log('armature is loaded');
            return;
        }

        loadDragonBones('牌面动画', 'db/小面/').then(() => setLoaded(true));
    }, [loaded]);

    const ecs = useEcs() as PixiEcsApp;

    useEffect(function() {
        if (!ecs || !loaded) return;
        console.log(ecs);
        ecs.interactiveSystem.onIntent = i => {
            console.log(i.intent, i);
        }
        ecs.start();

        (async function() {
            let i = 0;
            while (ecs.ticking) {
                ecs.update({
                    c1: {
                        armature: {
                            bundle: '牌面动画',
                            armature: 'Ingredient2'
                        },
                        armatureSlots: {
                            牌面: 'db/小面/红油.png'
                        },
                        armatureAnimations: {
                            flip: (i % 6) > 2
                        },
                        transform: {
                            r: { value: i++ * 30 / 180 * Math.PI },
                            x: { value: 300 * (i % 3) },
                            z: { value: 1 }
                        },
                        interactive: {
                            enabled: true,
                            roles: {
                                foo: 'bler'
                            }
                        }
                    },
                    z1: {
                        dropzone: {
                            roles: { foo: 'blah' },
                            zone: [0, 200, 800, 400]
                        }
                    }
                });
                await new Promise(r => setTimeout(r, 1000));
            }
        })();

        return function() {
            ecs.stop();
        }
    }, [loaded, ecs]);

    return null;
}
