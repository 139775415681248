"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.horizontalZones = exports.verticalZones = void 0;
const vertical = `
·········
·········
1234ccccc
1234ccccc
1234ccccc
·········
fffffffff
fffffffff
iiiiiiII·
iiiiiiIIm
iiiiiiII·
qqqwwweee
qqqwwweee
·hhhhhhh·
·hhhhhhh·
·hhhhhhh·
`;
exports.verticalZones = zones(vertical);
const horizontal = `
11223344cccccccc
fffffffff·iiiiii
fffffffff·iiiiii
qqqwwweee·iiiiii
qqqwwweee····m··
···············I
·hhhhhhhhhhhhh·I
·hhhhhhhhhhhhh··
·hhhhhhhhhhhhh··
`;
exports.horizontalZones = zones(horizontal);
function zones(layout) {
    return {
        player1: zone(layout, '1'),
        player2: zone(layout, '2'),
        player3: zone(layout, '3'),
        player4: zone(layout, '4'),
        customers: zone(layout, 'c'),
        flavors: zone(layout, 'f'),
        ingredients_pick: zone(layout, 'i'),
        ingredients_draw: zone(layout, 'I'),
        ingredients_discard: zone(layout, 'm'),
        bowl1: zone(layout, 'q'),
        bowl2: zone(layout, 'w'),
        bowl3: zone(layout, 'e'),
        hand: zone(layout, 'h'),
    };
}
function zone(layout, char) {
    const first = layout.indexOf(char);
    const last = layout.lastIndexOf(char);
    const minx = first - firstBefore(layout, first) - 1;
    const miny = countBefore(layout, first) - 1;
    const maxx = last - firstBefore(layout, last) - 1;
    const maxy = countBefore(layout, last) - 1;
    return { minx, miny, maxx, maxy };
}
function countBefore(layout, i, c = '\n') {
    let s = 0;
    while (i >= 0) {
        if (layout[i] == c)
            s++;
        i--;
    }
    return s;
}
function firstBefore(layout, i, c = '\n') {
    while (i >= 0) {
        if (layout[i] == c)
            return i;
        i--;
    }
    return -1;
}
