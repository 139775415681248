import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { NoodleControllerDemo, NoodleDemo, NoodlePlayableDemo, NoodleRoomController } from "../components/noodle";
import { GameControllerBar, PortalContainer, RoomBar, SelfAvatar, usePopup } from "../components/parse";
import { game } from "@tabletop/noodle-game";
import { ensureAccount, enterGameRoom, exitGameRoom, GameRoomController, sendMessage, useLiveMessages } from "../services/parse";
import { GameRoomRoute, useRoomRoute } from "./gameroom";

export function KitchenTableRoute() {
    return <Routes>
        <Route path="popup" element={<PopupTest />} />
        <Route path="hello" element={<div>hello?</div>} />
        <Route path="demo" element={<NoodleDemo />} />
        <Route path="playdemo" element={<NoodlePlayableDemo />} />
        <Route path="controllerdemo" element={<NoodleControllerDemo />} />
        <Route path="room/*" element={<GameRoomRoute children={<PrintRoom />} />} />
        <Route path="bar/*" element={<GameRoomRoute children={<PrintRoomBar />} />} />
        <Route path="gamebar/*" element={<GameRoomRoute children={<PrintGameBar />} />} />
        <Route path="playable/*" element={<GameRoomRoute children={<Playable />} />} />
    </Routes>
}

function PopupTest() {
    const popup = usePopup();
    return <div>
        <button onClick={() => popup.open()} children="User heads" />
        {
            popup.exist() && <PortalContainer active={popup.active()} onclick={() => popup.close()}>
                <div className="full flex dimmed">
                    <div className="collapse paper">
                        <SelfAvatar />
                        <SelfAvatar className="small loading p1" />
                        <SelfAvatar className="small loading p2" />
                        <SelfAvatar className="small loading p3" />
                        <SelfAvatar className="small loading p4" />
                        <SelfAvatar className="small loading p5" />
                        <SelfAvatar className="small loading p6" />
                        <SelfAvatar className="small loading p7" />
                    </div>
                </div>
            </PortalContainer>
        }
    </div>
}

function PrintRoom() {
    const ctx = useRoomRoute();
    const { roomId, room } = ctx;

    const [self, setSelf] = useState('loading');
    useEffect(() => {
        ensureAccount().then(user => setSelf(user.id));
    }, []);

    return <pre>
        roomId: {roomId}{'\n'}

        users: {room && room.get('users').join(', ')}{'\n'}

        self: {self}{'\n'}

        <button children="join" onClick={() => enterGameRoom(roomId)} />
        <button children="leave" disabled={!roomId} onClick={() => exitGameRoom(roomId!)} />
        {roomId && <Chat roomId={roomId} />}
        <button children="greet" disabled={!roomId} onClick={() => sendMessage(roomId!, 'Chat', "hello?")} />
    </pre>
}

function Chat(props: { roomId: string }) {
    const { roomId } = props;

    const msgs = useLiveMessages(roomId);
    console.log('chat:', msgs);
    return <pre>
        {msgs.map(msg => `[${msg.get('type')}] ${msg.get('content')}`).join('\n')}
    </pre>
}

function PrintRoomBar() {
    const ctx = useRoomRoute();
    const { room } = ctx;
    return <RoomBar room={room} />
}

function PrintGameBar() {
    const ctx = useRoomRoute();
    const { room } = ctx;

    const controller = useMemo(() => new GameRoomController(game, room), [room]);
    if (!room) return <span className="error"> 连接中……</span>;

    return <Fragment>
        <RoomBar room={room} />
        <GameControllerBar controller={controller} />
    </Fragment>
}

function Playable() {
    const ctx = useRoomRoute();
    const { room } = ctx;
    const controller = useMemo(() => new GameRoomController(game, room), [room]);
    if (!room) return <span className="error"> 连接中……</span>;

    return <Fragment>
        <div className="flex">
            <RoomBar room={room} />
            <GameControllerBar controller={controller} />
        </div>
        <NoodleRoomController controller={controller} />
    </Fragment>
}
