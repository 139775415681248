import { EcsCtx } from "@tabletop/ecs";
import { G, game } from "@tabletop/noodle-game";
import { createAppViewport, PixiEcsApp } from "@tabletop/pixidb";
import { createContext, HTMLAttributes, useContext, useEffect, useLayoutEffect, useMemo, useRef } from "react";
import { GameRoomController } from "../../services/parse";
import { CELL, makeEntity, makeZoneSprites, mapMap } from "./entities";
import { GameCardHelp } from "./help";

const ecsCtx = createContext(new EcsCtx());
export const EcsProvider = ecsCtx.Provider;
export const EcsConsumer = ecsCtx.Consumer;
export function useEcs() {
    return useContext(ecsCtx);
}

export function MountedDom(props: { dom: HTMLElement } & HTMLAttributes<HTMLDivElement>) {
    const ref = useRef<HTMLDivElement>(null);
    const { dom, ...others } = props;

    useLayoutEffect(function() {
        const e = ref.current!;
        e.appendChild(dom);
        return function() {
            e.removeChild(dom);
        }
    }, [dom]);

    return <div {...others} ref={ref} />
}

export function PixiApp(props: { children?: any }) {
    const { app, ecs } = useMemo(makeApp, []);

    useLayoutEffect(function() {
        app.resizeTo = app.view.parentElement!;
        window.dispatchEvent(new Event('resize'));
        return function() {
            app.destroy();
        }
    }, [app, ecs]);

    return <EcsProvider value={ecs}>
        <ZoneSprites/>
        <MountedDom className="flex full" style={{
            top: 0, position: 'absolute', background: 'burlywood'
        }} dom={app.view} />
        {props.children}
    </EcsProvider>
}

export function ZoneSprites() {
    const ecs = useEcs() as PixiEcsApp;

    useLayoutEffect(function() {
        const sprites = [...makeZoneSprites()];
        for(const sprite of sprites)
            ecs.viewport.addChild(sprite);
        return function(){
            for(const sprite of sprites)
                ecs.viewport.removeChild(sprite);
        }
    }, [ecs.viewport]);

    return null;
}

function makeApp() {
    const { app, viewport } = createAppViewport(CELL);
    app.stage.children[0].interactive = false;
    const ecs = new PixiEcsApp(viewport);
    return { app, ecs };
}

export function RoomControllerState(props: { controller: GameRoomController<G> }) {
    const c = props.controller;
    const ecs = useEcs() as PixiEcsApp;

    useEffect(() => {
        ecs.start();
        ecs.interactiveSystem.onIntent = i => {
            c.sendIntent(i);
        }
        const update = () => {
            const s = game.select(c.live.version, 'playerEntities', c.live.localPlayerId);
            const e = mapMap(s, makeEntity);
            ecs.update(e);
        }
        update();
        c.addEventListener('update', update);
        return () => {
            ecs.update({});
            ecs.stop();
            c.removeEventListener('update', update);
        }
    }, [c, ecs]);

    return <GameCardHelp/>;
}
