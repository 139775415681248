import React from "react";
import { Route, Routes } from "react-router-dom";
import { GameFlavorsHelp, GameHelp, GameIngredientsHelp } from "../components/noodle";

export function HelpRoutes() {
    return <Routes>
        <Route path="main" element={<GameHelp />} />
        <Route path="ingredients" element={<GameIngredientsHelp />} />
        <Route path="flavors" element={<GameFlavorsHelp />} />
    </Routes>
}
