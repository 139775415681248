"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.World = void 0;
const fast_json_patch_1 = require("fast-json-patch");
class World {
    constructor() {
        this.entity_components = {};
        this.component_entities = {};
    }
    add(ekey, ckey, comp) {
        this.entity_components[ekey] =
            this.entity_components[ekey] || {};
        this.component_entities[ckey] =
            this.component_entities[ckey] || {};
        this.entity_components[ekey][ckey] = comp;
        this.component_entities[ckey][ekey] = comp;
    }
    remove(ekey, ckey) {
        delete this.entity_components[ekey][ckey];
        delete this.component_entities[ckey][ekey];
        for (const _ in this.entity_components[ekey]) {
            return;
        }
        delete this.entity_components[ekey];
    }
    replace(ekey, ckey, comp) {
        this.entity_components[ekey][ckey] = comp;
        this.component_entities[ckey][ekey] = comp;
    }
    applyPatch(...patches) {
        fast_json_patch_1.applyPatch(this.entity_components, patches);
        for (const ekey in this.entity_components) {
            const e = this.entity_components[ekey];
            for (const ckey in e) {
                this.component_entities[ckey] =
                    this.component_entities[ckey] || {};
                this.component_entities[ckey][ekey] = e[ckey];
            }
        }
        for (const ckey in this.component_entities) {
            const c = this.component_entities[ckey];
            for (const ekey in c) {
                const e = this.entity_components[ekey];
                if (!e || !e[ckey]) {
                    delete c[ekey];
                }
            }
        }
    }
    makePatches(other) {
        return fast_json_patch_1.compare(this.entity_components, other);
    }
    entityKeys() {
        return Object.keys(this.entity_components);
    }
    componentKeys() {
        return Object.keys(this.component_entities);
    }
}
exports.World = World;
