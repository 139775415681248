"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.applySystemPatch = exports.System = void 0;
class System {
    constructor(ckey) {
        this.ckey = ckey;
        this._knownComponents = {};
    }
    addKnown(key) {
        this._knownComponents[key] = true;
    }
    removeKnown(key) {
        delete this._knownComponents[key];
    }
    isKnown(key) {
        return !!this._knownComponents[key];
    }
    onCreated(ekey, p) {
        this.onApply(ekey, p);
    }
    onDestroyed(_ekey) {
    }
    onApply(_ekey, _p) {
    }
    onTick(_delta) {
    }
}
exports.System = System;
function applySystemPatch(patch, systems, world) {
    const [, ekey, ckey, subkey] = patch.path.split('/');
    if (subkey || patch.op == 'replace') {
        for (let i = 0; i < systems.length; i++) {
            const system = systems[i];
            if (ckey && ckey !== system.ckey)
                continue;
            const newComponent = world.entity_components[ekey][system.ckey];
            if (!newComponent)
                continue;
            system.onApply(ekey, newComponent);
        }
    }
    else if (patch.op === 'add') {
        for (let i = 0; i < systems.length; i++) {
            const system = systems[i];
            if (ckey && ckey !== system.ckey)
                continue;
            const newComponent = world.entity_components[ekey][system.ckey];
            if (!newComponent)
                continue;
            system.onCreated(ekey, newComponent);
            system.addKnown(ekey);
        }
    }
    else if (patch.op === 'remove') {
        for (let i = systems.length - 1; i >= 0; i--) {
            const system = systems[i];
            if (ckey && ckey !== system.ckey)
                continue;
            if (!system.isKnown(ekey))
                continue;
            ;
            system.removeKnown(ekey);
            system.onDestroyed(ekey);
        }
    }
    else {
        throw new Error('no supported op ' + patch.op);
    }
}
exports.applySystemPatch = applySystemPatch;
