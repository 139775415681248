import React from "react";
import { Route, Routes } from "react-router-dom";
import { DBRoutes } from "./db";
import { GameRoomRoute } from "./gameroom";
import { KitchenTableRoute } from "./kitchensink";
import { ECSRoutes } from "./ecs";
import { HelpRoutes } from "./help";

export function AppRoutes() {
    return <Routes>
        <Route path="game/*" element={<GameRoomRoute />} />
        <Route path="test/*" element={<KitchenTableRoute />} />
        <Route path="db/*" element={<DBRoutes />} />
        <Route path="ecs/*" element={<ECSRoutes />} />
        <Route path="help/*" element={<HelpRoutes />} />
    </Routes>
}
