"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.compareCards = exports.cards = exports.measures = exports.isComplete = exports.score = exports.canMakeNoodle = exports.buildTypeMap = void 0;
function* ingredient(subtype, name, num, isSeafood) {
    for (let i = 0; i < num; i++)
        yield { key: '', type: '食材', subtype, name, isSeafood };
}
const ingredients = [
    ...ingredient('肉', '墨鱼', 3, true),
    ...ingredient('肉', '花甲', 3, true),
    ...ingredient('肉', '虾', 3, true),
    ...ingredient('肉', '牛肉', 3),
    ...ingredient('肉', '排骨', 3),
    ...ingredient('肉', '臊子', 5),
    ...ingredient('肉', '煎蛋', 3),
    ...ingredient('肉', '肥肠', 3),
    ...ingredient('菜', '软浆叶', 4),
    ...ingredient('菜', '香菇', 4),
    ...ingredient('菜', '番茄', 4),
    ...ingredient('菜', '豌豆尖', 4),
    ...ingredient('菜', '瓢儿白', 4),
    ...ingredient('菜', '芹菜', 4),
    ...ingredient('佐料', '红油', 12),
    ...ingredient('佐料', '盐须', 6),
    ...ingredient('佐料', '葱花', 6),
];
function* flavor(name, minor, major, req, rec, ban, copies) {
    for (let i = 0; i < copies; i++)
        yield { key: '', type: '点单', name, minor, major, req, rec, ban };
}
const flavors = [
    ...flavor('番茄煎蛋面', 8, 13, ['番茄', '煎蛋'], ['葱花'], ['红油'], 2),
    ...flavor('红烧排骨面', 8, 13, ['排骨', '红油'], ['葱花'], ['盐须'], 2),
    ...flavor('红烧牛肉面', 8, 13, ['牛肉', '红油'], ['盐须'], [], 2),
    ...flavor('海味面', 8, 13, ['海鲜', '海鲜'], [], ['红油'], 2),
    ...flavor('脆绍面', 5, 8, ['红油', '臊子'], ['葱花'], [], 2),
    ...flavor('怪味面', 8, 13, ['臊子', '海鲜'], ['葱花'], ['盐须'], 2),
    ...flavor('肥肠面', 8, 13, ['肥肠', '红油'], ['盐须'], [], 2),
    ...flavor('碗杂面', 5, 8, ['臊子', '菜'], ['红油', '盐须'], [], 2),
];
const bargains = [...flavor('特价面', 3, 5, [], [], [], 15)];
function customer(name, condition, dices, throws) {
    return { key: '', type: '顾客', name, condition, dices, throws };
}
const customers = [
    customer('大彪汉', '最多肉', 2, 3),
    customer('花泽', '最多盐须', 2, 3),
    customer('海王', '最多海鲜', 2, 3),
    customer('辣妹子', '最多红油', 2, 3),
    customer('小学生', '最多小份', 2, 3),
    customer('大胃王', '最多大份', 2, 3),
    customer('打工人', '最多特价', 3, 3),
    customer('急性子', '最多面', 2, 3),
];
ingredients.forEach((c, i) => c.key = `ING#${i}-${c.name}`);
flavors.forEach((c, i) => c.key = `FLA#${i}-${c.name}`);
customers.forEach((c, i) => c.key = `CUS#${i}-${c.name}`);
bargains.forEach((c, i) => c.key = `BAR#${i}-${c.name}`);
const all = {};
ingredients.forEach(c => all[c.key] = c);
flavors.forEach(c => all[c.key] = c);
customers.forEach(c => all[c.key] = c);
bargains.forEach(c => all[c.key] = c);
function buildTypeMap(keys) {
    const byname = {};
    const subtype = {};
    for (const key of keys) {
        const card = exports.cards.all[key];
        subtype[card.type] = subtype[card.type] || {};
        subtype[card.type][card.name] = true;
        if (card.type !== '食材')
            continue;
        const ing = card;
        byname[ing.name] = (byname[ing.name] || 0) + 1;
        subtype[ing.subtype] = subtype[ing.subtype] || {};
        subtype[ing.subtype][ing.name] = true;
        if (ing.isSeafood) {
            subtype['海鲜'] = subtype['海鲜'] || {};
            subtype['海鲜'][ing.name] = true;
        }
    }
    return { byname, subtype };
}
exports.buildTypeMap = buildTypeMap;
function canMakeNoodle(typeMap, flavor, scoring = false) {
    const { byname, subtype } = typeMap;
    if (!scoring && subtype['点单'])
        return false;
    const checks = {
        ...byname,
    };
    for (const key in subtype) {
        checks[key] = Object.keys(subtype[key]).length;
    }
    let ok = true;
    for (const one of flavor.req) {
        if (checks[one] > 0) {
            checks[one]--;
        }
        else {
            ok = false;
            break;
        }
    }
    return ok;
}
exports.canMakeNoodle = canMakeNoodle;
function score(keys) {
    const reasons = [];
    const { byname, subtype } = buildTypeMap(keys);
    for (const name of Object.keys(byname)) {
        if (byname[name] >= 3) {
            reasons.push(['三张' + name, 5]);
        }
        else if (byname[name] >= 2) {
            reasons.push(['一对' + name, 2]);
        }
    }
    if (subtype['肉'])
        reasons.push(['有肉', 3]);
    if (subtype['菜'])
        reasons.push(['有菜', 2]);
    if (subtype['佐料'])
        reasons.push(['有佐料', 1]);
    for (const key of keys) {
        const card = exports.cards.all[key];
        if (card.type !== '点单')
            continue;
        const flavor = card;
        if (!canMakeNoodle({ byname, subtype }, flavor, true))
            continue;
        const num = Object.keys(byname).map(key => byname[key]).reduce((a, b) => a + b, 0);
        if (num >= 5)
            reasons.push([flavor.name + '·大份', flavor.major]);
        else
            reasons.push([flavor.name + '·小份', flavor.minor]);
        for (const key of flavor.rec) {
            if (byname[key] || subtype[key]) {
                reasons.push([key + '推荐', 2]);
            }
        }
        for (const key of flavor.ban) {
            if (byname[key] || subtype[key]) {
                reasons.push([key + '禁选', -3]);
            }
        }
    }
    return reasons;
}
exports.score = score;
function isComplete(bowl) {
    return !!bowl.find(ing => exports.cards.all[ing].type === '点单');
}
exports.isComplete = isComplete;
function bestBowl(p, match) {
    return Math.max(...p.bowls.map(bowl => countBowl(bowl, match)));
}
function countBowl(bowl, match) {
    let s = 0;
    for (const one of bowl)
        match(one) && (s += 1);
    return s;
}
function topPlayer(g, scorePlayer) {
    const counts = g.players.map(scorePlayer);
    const max = Math.max(...counts);
    let besti = -1;
    let tops = 0;
    for (let i = 0; i < counts.length; i++) {
        if (counts[i] === max) {
            tops++;
            besti = i;
        }
    }
    if (tops === 1) {
        return besti;
    }
    else {
        return -1;
    }
}
exports.measures = {
    最多肉(g) {
        return topPlayer(g, p => bestBowl(p, ing => {
            return exports.cards.all[ing].subtype === '肉';
        }));
    },
    最多盐须(g) {
        return topPlayer(g, p => bestBowl(p, ing => {
            return exports.cards.all[ing].name === '盐须';
        }));
    },
    最多海鲜(g) {
        return topPlayer(g, p => bestBowl(p, ing => {
            return !!exports.cards.all[ing].isSeafood;
        }));
    },
    最多红油(g) {
        return topPlayer(g, p => bestBowl(p, ing => {
            return exports.cards.all[ing].name === '红油';
        }));
    },
    最多小份(g) {
        return topPlayer(g, p => {
            let s = 0;
            for (const bowl of p.bowls) {
                const ings = countBowl(bowl, ing => {
                    return exports.cards.all[ing].type === '食材';
                });
                if (ings < 5)
                    s++;
            }
            return s;
        });
    },
    最多大份(g) {
        return topPlayer(g, p => {
            let s = 0;
            for (const bowl of p.bowls) {
                const ings = countBowl(bowl, ing => {
                    return exports.cards.all[ing].type === '食材';
                });
                if (ings >= 5)
                    s++;
            }
            return s;
        });
    },
    最多特价(g) {
        return topPlayer(g, p => {
            let s = 0;
            for (const bowl of p.bowls) {
                for (const key of bowl) {
                    if (exports.cards.all[key].name === '特价面') {
                        s += 1;
                        break;
                    }
                }
            }
            return s;
        });
    },
    最多面(g) {
        return topPlayer(g, p => {
            let s = 0;
            for (const bowl of p.bowls) {
                for (const key of bowl) {
                    if (exports.cards.all[key].type === '点单') {
                        s += 1;
                        break;
                    }
                }
            }
            return s;
        });
    },
};
exports.cards = {
    ingredients: ingredients.map(c => c.key),
    flavors: flavors.map(c => c.key),
    customers: customers.map(c => c.key),
    bargains: bargains.map(c => c.key),
    all
};
function compareCards(a, b) {
    const ca = exports.cards.all[a];
    const cb = exports.cards.all[b];
    if (ca.type != cb.type)
        return ca.type.localeCompare(cb.type);
    if (ca.type !== '食材') {
        return ca.name.localeCompare(cb.name);
    }
    const ia = ca;
    const ib = cb;
    if (ia.isSeafood != ib.isSeafood) {
        return ia.isSeafood ? -1 : 1;
    }
    if (ia.subtype != ib.subtype) {
        return ia.subtype.localeCompare(ib.subtype);
    }
    return ia.name.localeCompare(ib.name);
}
exports.compareCards = compareCards;
