import { useEffect, useMemo, useReducer, useState } from "react";
import { Parse } from "./setup";

// const log = (...msgs: any[]) => console.log('[Game Room]', ...msgs);

export async function enterGameRoom(roomId?: string) {
    // log('trying to enter room', roomId);
    return await Parse.Cloud.run("enterGameRoom", { roomId }) as string;
}

export async function exitGameRoom(roomId: string) {
    // log('trying to exit room', roomId);
    return await Parse.Cloud.run("exitGameRoom", { roomId }) as void;
}

export async function sendMessage(roomId: string, type: string, content: string) {
    // log('trying to send message in room', roomId, 'of type', type);
    return await Parse.Cloud.run("sendGameRoomMessage", { roomId, type, content }) as string;
}

export function useLiveQuery(query: Parse.Query) {
    const [sub, setSub] = useState(null as (null | Parse.LiveQuerySubscription));
    const [connected, setConnected] = useState(false);

    useEffect(function() {
        let cancel = false;
        let sub = null as (null | Parse.LiveQuerySubscription);
        const onOpen = () => {
            if (cancel) return;
            setConnected(true);
        }
        const onClose = () => {
            if (cancel) return;
            setConnected(false);
        }

        query.subscribe().then(connectedSub => {
            if (cancel) return;

            sub = connectedSub;
            sub.on('open', onOpen);
            sub.on('close', onClose);
            setSub(sub);
        });

        return function() {
            cancel = true;

            if (sub) {
                sub.off('open', onOpen);
                sub.off('close', onClose);
                sub.unsubscribe();
            }
        }
    }, [query]);

    return { sub, connected };
}

export function useLiveRoomObject(roomId: string) {
    const [room, setRoom] = useState(null as (null | Parse.Object));

    useEffect(function() {
        let cancel = false;
        new Parse.Query("GameRoom").get(roomId).then(room => {
            if (cancel) return;
            setRoom(room);
        });
        return function() {
            cancel = true;
        }
    }, [roomId, setRoom]);

    const query = useMemo(() => new Parse.Query("GameRoom").equalTo("objectId", roomId), [roomId]);
    const { sub, connected } = useLiveQuery(query);

    useEffect(function() {
        if (!sub || !connected) return;

        const onUpdate = (room: Parse.Object) => {
            setRoom(room);
        };

        sub.on('update', onUpdate);

        return function() {
            sub.off('update', onUpdate);
        }
    }, [sub, connected, setRoom]);

    return room;
}

async function getHistoryIntents(roomId: string) {
    const query = new Parse.Query("GameRoomMessage")
        .equalTo("roomId", roomId)
        .equalTo("type", 'intent')
        .descending("createdAt")
        .limit(100);

    let page = 0;
    const all = [] as Parse.Object[];
    while (true) {
        query.skip(page * 100);
        page++;
        const results = await query.find();
        for (let i = 0; i < results.length; i++) {
            const one = results[i];
            const intent = JSON.parse(one.get('content'));
            if (intent.intent === 'setup') {
                all.push(...results.slice(0, i + 1));
                return all.reverse();
            }
        }
        if (results.length < 100 || !results.length) {
            return [];
        }
        all.push(...results);
    }
}

function useHistoryIntents(roomId: string) {
    const [result, setResult] = useState({ done: false, messages: [] as Parse.Object[] });
    useEffect(function() {
        let cancel = false;
        getHistoryIntents(roomId).then(
            messages => cancel || setResult({ done: true, messages })
        );
        return function() {
            cancel = true;
        }
    }, [roomId]);

    return result;
}

export function useLiveMessages(roomId: string) {
    const [, update] = useReducer(i => i + 1, 0);
    const {done, messages} = useHistoryIntents(roomId);

    const query = useMemo(() => new Parse.Query("GameRoomMessage").equalTo("roomId", roomId), [roomId]);
    const { sub, connected } = useLiveQuery(query);

    useEffect(function() {
        if (!done || !sub || !connected) return;

        const onUpdate = (message: Parse.Object) => {
            messages.push(message);
            update();
        };

        sub.on('create', onUpdate);

        return function() {
            sub.off('create', onUpdate);
        }
    }, [done, sub, connected, messages]);

    return messages;
}

export async function recentRoomMessages(roomId: string, skip = 0, limit = 100) {
    return await new Parse.Query("GameRoomMessage").equalTo("roomId", roomId).descending("updatedAt").limit(limit).skip(skip).find();
}
