"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.createAppViewport = void 0;
const pixi_js_1 = require("pixi.js");
__exportStar(require("./grid"), exports);
function createAppViewport(appCellSize = 100) {
    const res = window.devicePixelRatio;
    const app = new pixi_js_1.Application({
        antialias: true,
        resolution: res,
        transparent: true
    });
    const viewport = new pixi_js_1.Container();
    const panner = makePanner(async (dx, dy) => {
        const rate = .25;
        const n = 15;
        let s = 0;
        for (let i = 0; i < n; i++) {
            await new Promise(r => requestAnimationFrame(r));
            const ds = rate * Math.pow((1 - rate), i);
            viewport.x += dx * ds;
            viewport.y += dy * ds;
            s += ds;
        }
        viewport.x += dx * (1 - s);
        viewport.y += dy * (1 - s);
    }, () => appCellSize * viewport.scale.x);
    app.stage.addChild(panner);
    app.stage.addChild(viewport);
    app.view.classList.add('pixi-canvas');
    const resize = () => {
        if (!app.renderer)
            return;
        if (!app.view.parentElement)
            return;
        if (!app.resizeTo)
            return;
        app.resize();
        const { width, height } = app.view.parentElement.getBoundingClientRect();
        const cell = measureCellSize(width, height);
        const z = cell / appCellSize;
        viewport.scale.set(z, z);
        viewport.position.set(width / 2, height / 2);
        panner.width = width;
        panner.height = height;
    };
    const unload = () => {
        window.removeEventListener('resize', resize);
        window.removeEventListener('beforeunload', unload);
    };
    window.addEventListener('resize', resize);
    window.addEventListener('beforeunload', unload);
    return { app, viewport, panner };
}
exports.createAppViewport = createAppViewport;
function measureCellSize(w, h, wcells = 16, hcells = 9) {
    const min = Math.min(w, h);
    const max = w + h - min;
    const mincells = Math.min(wcells, hcells);
    const maxcells = wcells + hcells - mincells;
    return Math.min(min / mincells, max / maxcells);
}
function makePanner(onPan, step) {
    const sprite = new pixi_js_1.Sprite(pixi_js_1.Texture.WHITE);
    sprite.alpha = 0.01;
    sprite.zIndex = -100;
    let x = 0;
    let y = 0;
    const onDown = (e) => {
        const pt = e.data.getLocalPosition(sprite.parent);
        x = pt.x;
        y = pt.y;
        sprite.parent.interactive = true;
        sprite.parent.on('pointermove', onMove);
        sprite.parent.on('mousemove', onMove);
        sprite.parent.on('pointerup', onUp);
        sprite.parent.on('mouseup', onUp);
        sprite.parent.on('pointerout', onUp);
        sprite.parent.on('mouseout', onUp);
    };
    const onMove = (e) => {
        const s = step();
        const pt = e.data.getLocalPosition(sprite.parent);
        const nx = x + Math.round((pt.x - x) / s) * s;
        const ny = y + Math.round((pt.y - y) / s) * s;
        if (nx != x || ny != y) {
            onPan(nx - x, ny - y);
            x = nx;
            y = ny;
        }
    };
    const onUp = () => {
        sprite.parent.interactive = false;
        sprite.parent.off('pointermove', onMove);
        sprite.parent.off('mousemove', onMove);
        sprite.parent.off('pointerup', onUp);
        sprite.parent.off('mouseup', onUp);
        sprite.parent.off('pointerout', onUp);
        sprite.parent.off('mouseout', onUp);
    };
    sprite.interactive = true;
    sprite.on('pointerdown', onDown);
    sprite.on('mousedown', onDown);
    return sprite;
}
