"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.stack = exports.EventExpansion = exports.commonEvents = exports.effect = exports.event = void 0;
function event(event, desc, others) {
    return { type: 'event', event, desc, ...others };
}
exports.event = event;
function effect(effect, desc, others) {
    return { type: 'effect', effect, desc, ...others };
}
exports.effect = effect;
exports.commonEvents = {
    game: () => event('game', 'The entire game'),
    finish: () => event('finish', 'The game conclusion condition is achieved'),
    invalid: () => event('invalid', 'Invalid game state detected according to rules'),
};
function init() {
    return {
        nodes: [{
                node: exports.commonEvents.game(),
                parent: -1
            }],
        leafs: [0],
        resolved: [],
    };
}
class EventExpansion {
    constructor() {
        const { nodes, leafs, resolved } = init();
        this.nodes = nodes;
        this.leafs = leafs;
        this.resolved = resolved;
    }
    head() {
        const e = this.nodes[this.leafs[0]];
        return e && e.node;
    }
    hasHead() {
        return !!this.head;
    }
    context(event) {
        let e = this.nodes[this.leafs[0]];
        while (e) {
            if (e.node.type === 'event' && e.node.event === event)
                return e.node;
            e = this.nodes[e.parent];
        }
        return null;
    }
    expandHead(inputs) {
        if (!this.hasHead())
            throw new Error("nothing to expand");
        if (this.head().event === 'finish') {
            throw new Error("game already finished");
        }
        const sequence = [];
        for (const one of inputs) {
            if (!one)
                continue;
            if (one.type === 'event' && one.event == 'invalid')
                throw new Error("not expanding an invalid sequence");
            sequence.push(one);
        }
        const parent = this.leafs[0];
        const expansions = sequence.map(node => {
            return { node, parent };
        });
        this.leafs.splice(0, 1, ...expansions.map((_, i) => i + this.nodes.length));
        this.nodes.push(...expansions);
        let i = 0;
        while (i < this.leafs.length && this.nodes[this.leafs[i]].node.type == 'effect') {
            i++;
        }
        if (i > 0) {
            const resolved = this.leafs.splice(0, i);
            this.resolved.push(...resolved);
        }
        return i;
    }
    buildTree() {
        const nodes = this.nodes.map(node => {
            return { ...node, children: [] };
        });
        for (const one of nodes) {
            if (one.parent == -1)
                continue;
            nodes[one.parent].children.push(one);
        }
        return nodes;
    }
    indent(a, b) {
        return a.node.event == b.node.event ? 0 : 2;
    }
    toTreeString(node, indent = 0) {
        const name = node.node.type == 'event' ? node.node.event : node.node.effect;
        const self = ''.padStart(indent, ' ') +
            `[${node.node.type}]${node.node == this.head() ? '*' : ' '}(${name}) ${node.node.desc}\n`;
        const children = node.children
            .map(child => this.toTreeString(child, indent + this.indent(child, node))).join('');
        return self + children;
    }
    toString() {
        return this.toTreeString(this.buildTree()[0]);
    }
    fromData(obj) {
        const { nodes, leafs, resolved } = obj || init();
        this.nodes = nodes;
        this.leafs = leafs;
        this.resolved = resolved;
    }
    toData() {
        return {
            nodes: this.nodes,
            leafs: this.leafs,
            resolved: this.resolved
        };
    }
}
exports.EventExpansion = EventExpansion;
exports.stack = {
    stack: new EventExpansion(),
    onCreate(t) {
        this.stack.fromData(t);
        return this.stack;
    },
    onDestroy(p) {
        return p.toData();
    }
};
