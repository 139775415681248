"use strict";
/**
 * some fp utilities, I guess
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.multicast = exports.multiplex = void 0;
function multiplex(callbacks, key) {
    return function (...args) {
        const callkey = key(...args);
        const callback = callbacks[callkey];
        if (!callback)
            throw new Error("failed to multiplex call to key " + callkey);
        return callback(...args);
    };
}
exports.multiplex = multiplex;
function multicast(...callbacks) {
    return function (...args) {
        return callbacks.map(cb => cb(...args));
    };
}
exports.multicast = multicast;
