import React, { Fragment, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { DBAnimationBool, DBArmatureComponent, DBArmatureSlotReplacement, PixiApp, PixiBackground, PixiEventHooks, PixiTransform, Viewport } from "tabletop-game";
import { cards } from "@tabletop/noodle-game";

export function DBRoutes() {
    return <Routes>
        <Route path="animation2" element={<Animation2 />} />
    </Routes>
}

function Animation2() {
    const w = window.innerWidth;
    const h = Math.round(w * .6);
    const res = window.devicePixelRatio;
    const zoom = w / 1000 / 5;
    return <PixiApp className="pixi-container"
        pixiParams={{
            antialias: true,
            width: w,
            height: h,
            resolution: res,
        }}>
        <Viewport zoom={zoom} y={300}>
            <PixiBackground url="db/小面/bg.jpg">
                <PixiTransform sx={2} sy={2} x={-2500} y={-2000} />
            </PixiBackground>
            <GameCard cardkey={cards.ingredients[0]}>
                <PixiTransform x={-500} />
                <Animation flag="shrink"/>
            </GameCard>
            <GameCard cardkey={cards.flavors[0]}>
                <PixiTransform x={500} />
                <Animation flag="shrink"/>
            </GameCard>
            <GameCard cardkey={cards.customers[0]}>
                <PixiTransform y={1000} />
                <Animation flag="shrink"/>
            </GameCard>
        </Viewport>
    </PixiApp>
}

const armatures: { [key: string]: string } = {
    '食材': 'Ingredient2',
    '顾客': 'Customer2',
    '点单': 'Flavor2'
};
function GameCard(props: { cardkey: string, children?: any }) {
    const card = cards.all[props.cardkey];
    const armature = card && armatures[card.type];
    if (!armature) return null;

    return <DBArmatureComponent path="db/小面/" armature={armature} bundle="牌面动画">
        <DBArmatureSlotReplacement slot="牌面" replaced={`db/小面/${card.name}.png`} />
        {props.children}
    </DBArmatureComponent>
}

function Animation(props: { flag: string }) {
    const [on, setOn] = useState(true);
    return <Fragment>
        <PixiEventHooks event="click" callback={() => setOn(!on)} />
        <DBAnimationBool on={on} animation={props.flag} />
    </Fragment>
}
