"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.play = exports.finish = exports.turn = exports.initialize = exports.deal = exports.setup = exports.game = void 0;
const game_runner_1 = require("@tabletop/game-runner");
const cards_1 = require("./cards");
const schema_1 = require("./schema");
function* game() {
    yield schema_1.gameEvent('setup', "游戏布置");
}
exports.game = game;
function* setup(_, e) {
    const { intent, players } = e.intent;
    if (intent !== 'setup')
        throw new Error('请决定游戏配置');
    yield schema_1.gameEffect('setup', `为玩家 ${players.join(',')} 布置游戏`, {
        players
    });
    yield schema_1.gameEvent('initialize', '初始化桌面');
    yield schema_1.gameEvent('turn', `${players[0]}的回合`, { playerId: 0, actions: 1 });
}
exports.setup = setup;
function* deal(g, e) {
    const { deck, num } = e;
    if (g[deck].draw.length < num) {
        yield dealCards([deck, 'discard'], [deck, 'draw'], g[deck].discard.length);
    }
    yield dealCards([deck, 'draw'], [deck, 'pick'], num);
}
exports.deal = deal;
function dealCards(from, to, n = 1) {
    return schema_1.gameEffect('deal', `从 ${from.join('.')} 向 ${to.join('.')}发 ${n} 张牌`, {
        from, to, n
    });
}
function* initialize(g) {
    yield schema_1.gameEffect('shuffle', '将食材牌组洗牌', { path: ['ingredients', 'draw'] });
    yield schema_1.gameEffect('shuffle', '将点单牌组洗牌', { path: ['flavors', 'draw'] });
    yield schema_1.gameEffect('shuffle', '将顾客牌组洗牌', { path: ['customers', 'draw'] });
    yield dealCards(['ingredients', 'draw'], ['ingredients', 'pick'], 3);
    yield dealCards(['flavors', 'draw'], ['flavors', 'pick'], g.players.length + 1);
    yield dealCards(['customers', 'draw'], ['customers', 'pick'], 3);
    yield schema_1.gameEffect('throwCustomerDices', '投顾客骰子');
    for (let i = 0; i < g.players.length; i++) {
        yield dealCards(['ingredients', 'draw'], ['players', i, 'hand'], 3);
        yield dealCards(['flavors', 'draw'], ['players', i, 'hand'], 1);
    }
}
exports.initialize = initialize;
function* turn(g, e) {
    const { playerId, actions } = e;
    const bowls = g.players[playerId].bowls;
    if (cards_1.isComplete(bowls[0]) && cards_1.isComplete(bowls[1]) && cards_1.isComplete(bowls[2])) {
        yield schema_1.gameEvent('finish', `${g.players[playerId].name}完成了三碗面，游戏结束！`);
        return;
    }
    yield schema_1.gameEvent('play', `${g.players[playerId].name}的行动`, { playerId, actions });
    const next = (playerId + 1) % g.players.length;
    yield schema_1.gameEvent('turn', `${g.players[next].name}的回合`, { playerId: next, actions: 2 });
}
exports.turn = turn;
function* finish() {
}
exports.finish = finish;
function bid(bowl) {
    if (isNaN(bowl))
        return parseInt(bowl.slice(-1));
    return bowl;
}
function* play(g, e) {
    const { playerId, intent, prevPlay, actions, desc } = e;
    const player = g.players[playerId];
    if (!intent)
        throw new Error("请决定行动");
    let card = {};
    let nextActions = actions;
    switch (intent.intent) {
        case 'pass':
            return;
        case 'play_ingredient':
            card = cards_1.cards.all[intent.hand];
            const bowl = player.bowls[bid(intent.bowl)];
            if (cards_1.isComplete(bowl))
                throw new Error("不能在已完成的面里下食材");
            if (bowl.length >= 5)
                throw new Error("一碗面最多5个食材");
            if (!prevPlay || !prevPlay.intent ||
                prevPlay.intent.intent !== 'play_ingredient' ||
                bid(prevPlay.intent.bowl) !== bid(intent.bowl) ||
                cards_1.cards.all[prevPlay.intent.hand].name !== card.name)
                nextActions -= 1;
            yield schema_1.gameEffect('pick', `${player.name} 打出 ${card.name} 到第 ${bid(intent.bowl) + 1} 碗面`, {
                from: ['players', playerId, 'hand'],
                to: ['players', playerId, 'bowls', bid(intent.bowl)],
                items: [intent.hand]
            });
            break;
        case 'pick_ingredient':
            card = cards_1.cards.all[intent.pick];
            nextActions -= 1;
            yield schema_1.gameEffect('pick', `${player.name} 从食材区选择了 ${card.name}`, {
                from: ['ingredients', 'pick'],
                to: ['players', playerId, 'hand'],
                items: [intent.pick]
            });
            yield schema_1.gameEvent('deal', `发了一张新食材`, {
                deck: 'ingredients',
                num: 1
            });
            break;
        case 'draw_ingredient':
            if (g.ingredients.draw.length === 0) {
                throw new Error('食材牌堆已空！');
            }
            nextActions -= 1;
            yield schema_1.gameEffect('deal', `${player.name} 抽了一张食材`, {
                from: ['ingredients', 'draw'],
                to: ['players', playerId, 'hand'],
                n: 1
            });
            yield schema_1.gameEffect('deal', `旧食材被清空`, {
                from: ['ingredients', 'pick'],
                to: ['ingredients', 'discard'],
                n: 3
            });
            yield schema_1.gameEvent('deal', `发了3张新食材`, {
                deck: 'ingredients',
                num: 3
            });
            break;
        case 'pick_flavor':
            card = cards_1.cards.all[intent.pick];
            if (card.type !== '点单' ||
                !cards_1.canMakeNoodle(cards_1.buildTypeMap(player.bowls[bid(intent.bowl)]), card)) {
                throw new Error("未达到点单要求");
            }
            nextActions -= 1;
            const bargain = g.flavors.bargain.indexOf(intent.pick) > -1;
            yield schema_1.gameEffect('pick', `${player.name} 完成一碗 ${card.name}`, {
                from: bargain ? ['flavors', 'bargain'] : ['flavors', 'pick'],
                to: ['players', playerId, 'bowls', bid(intent.bowl)],
                items: [intent.pick]
            });
            if (!bargain)
                yield schema_1.gameEvent('deal', `发了一张新点单`, {
                    deck: 'flavors',
                    num: 1
                });
            break;
        case 'play_flavor':
            card = cards_1.cards.all[intent.hand];
            if (card.type !== '点单' ||
                !cards_1.canMakeNoodle(cards_1.buildTypeMap(player.bowls[bid(intent.bowl)]), card)) {
                throw new Error("未达到点单要求");
            }
            nextActions -= 1;
            yield schema_1.gameEffect('pick', `${player.name} 从手牌完成了一碗 ${card.name}`, {
                from: ['players', playerId, 'hand'],
                to: ['players', playerId, 'bowls', bid(intent.bowl)],
                items: [intent.hand]
            });
            break;
        case 'pick_customer':
            if (!prevPlay || !prevPlay.intent ||
                (prevPlay.intent.intent !== 'play_flavor' &&
                    prevPlay.intent.intent !== 'pick_flavor')) {
                throw new Error("只能在完成点单后选择顾客");
            }
            card = cards_1.cards.all[intent.customer];
            if (card.type !== '顾客')
                throw new Error("需要选择顾客");
            const top = cards_1.measures[card.condition](g);
            if (top !== playerId)
                return [game_runner_1.commonEvents.invalid()];
            yield schema_1.gameEffect('takeCustomer', `${player.name} 满足了顾客 ${card.name}`, {
                player: player.name,
                i: g.customers.pick.indexOf(intent.customer)
            });
            break;
        default:
            throw new Error("请决定行动");
    }
    if (nextActions < 0)
        throw new Error("行动点数不够");
    if (nextActions >= 0) {
        yield schema_1.gameEvent('play', desc, { playerId, actions: nextActions, prevPlay: e });
    }
}
exports.play = play;
